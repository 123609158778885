function DotLoader({ className }: { className?: string }) {
  return (
    <div className={`loader-custom ${className}`}>
      <div className="dot dot-1" />
      <div className="dot dot-2" />
      <div className="dot dot-3" />
      <div className="dot dot-4" />
      <div className="dot dot-5" />
    </div>
  );
}
export default DotLoader;
