import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WagmiProvider } from 'wagmi';
import AppRoutes from './AppRoutes';
import { config } from './config/wagmi';
import { RootState } from './store';

function Providers() {
  const queryClient = new QueryClient();
  const { chainId } = useSelector((state: RootState) => state.auth);
  const { supportedChainIds, supportedChainsData } = useSelector((state: RootState) => state.chains);
  const wagmiConfig = useMemo(
    () => config({ supportedChainIds, supportedChainsData }),
    [supportedChainIds, supportedChainsData],
  );
  return (
    <WagmiProvider config={wagmiConfig} reconnectOnMount>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme()} initialChain={chainId}>
          <AppRoutes />
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default Providers;
