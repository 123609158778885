import { ApiRpcResponse } from '@dzapio/dzap-sdk';
import { PublicClient, WalletClient, encodeFunctionData, getAddress, parseUnits } from 'viem';
import { AvailableAbis } from '../enums';
import { HexString } from '../types';
import ClientProvider from './ClientProvider';
import getAbi from './AbiUtils';

export const isAddress = (address: string) => {
  try {
    return getAddress(address);
  } catch {
    return false;
  }
};

export const getPublicClient = ({
  chainRpcData,
  chainId,
}: {
  chainRpcData: ApiRpcResponse[];
  chainId: number;
}): PublicClient => ClientProvider.getInstance().getPublicClient({ chainRpcData, chainId });

export const readContract = async ({
  chainRpcData,
  chainId,
  contractAddress,
  abiPath,
  functionName,
  args = [],
}: {
  chainRpcData: ApiRpcResponse[];
  chainId: number;
  contractAddress: HexString;
  abiPath: AvailableAbis;
  functionName: string;
  args?: unknown[];
}) => {
  try {
    const res = await getPublicClient({ chainRpcData, chainId }).readContract({
      address: contractAddress,
      abi: getAbi(abiPath),
      functionName,
      args,
    });
    return res;
  } catch (e) {
    console.log(e);
    return { status: 'failed' };
  }
};

export const simulateContract = async ({
  chainRpcData,
  chainId,
  contractAddress,
  abiPath,
  functionName,
  args = [],
  userAddress,
  gasPrice,
  value = '0',
}: {
  chainRpcData: ApiRpcResponse[];
  chainId: number;
  contractAddress: HexString;
  abiPath: AvailableAbis;
  functionName: string;
  args?: unknown[];
  userAddress: HexString;
  value?: string | bigint | number;
  gasPrice?: number;
}) => {
  const publicClient = getPublicClient({ chainRpcData, chainId });
  try {
    const { request } = await publicClient.simulateContract({
      address: contractAddress,
      abi: getAbi(abiPath),
      functionName,
      args,
      value: BigInt(value) as unknown as undefined,
      account: userAddress,
      gasPrice: gasPrice ? BigInt(gasPrice) : undefined,
    });
    return request;
  } catch (e: unknown) {
    console.log(e);
    return null;
  }
};

export const writeContract = async ({
  chainRpcData,
  chainId,
  contractAddress,
  abiPath,
  functionName,
  args = [],
  userAddress,
  gasPrice,
  walletClient,
  value = '0',
}: {
  chainRpcData: ApiRpcResponse[];
  chainId: number;
  contractAddress: HexString;
  abiPath: AvailableAbis;
  functionName: string;
  args?: unknown[];
  userAddress: HexString;
  value?: string | bigint | number;
  gasPrice?: number;
  walletClient: WalletClient;
}): Promise<HexString | null> => {
  try {
    const request = await simulateContract({
      contractAddress,
      abiPath,
      functionName,
      args,
      userAddress,
      value: BigInt(value),
      gasPrice,
      chainId,
      chainRpcData,
    });
    if (!request) {
      return null;
    }
    return await walletClient.writeContract(request);
  } catch (e: unknown) {
    console.log(e);
    // @dev to be used later for error handling cases
    // const errorReason = ((e as BaseError)?.cause as ContractFunctionRevertedError)?.reason as string;
    return null;
  }
};

export const parseUnitsInWei = (amount: number | string, decimals: number): bigint =>
  parseUnits(amount.toString(), decimals);

export const getEncodedFunctionData = ({
  abiPath,
  params,
  functionName,
}: {
  abiPath: AvailableAbis;
  params: unknown[];
  functionName: string;
}) => {
  return encodeFunctionData({
    abi: getAbi(abiPath),
    functionName,
    args: params,
  });
};
