import baseApiUrl from '../AppConfig';

const batchSwap = 'swap';
const token = 'token';
const dca = 'dca';
const bridge = 'bridge';
const address = 'address';

const urls = {
  apiGetStats: `${baseApiUrl}/${batchSwap}/get/stats`,
  getTokenDetails: `${baseApiUrl}/${token}/details`,
  getTokensPrice: `${baseApiUrl}/${token}/price`,
  getAllTokens: `${baseApiUrl}/${token}/tokens`,
  getAllChains: `${baseApiUrl}/chains`,
  // TODO : Move to sdk
  getBalanceAll: `${baseApiUrl}/${token}/balance-of`,

  //   DCA
  getAllDCATokens: `${baseApiUrl}/${dca}/tokens`,

  getBridgeStatus: `${baseApiUrl}/${bridge}/status`,

  // user
  userLogin: `${baseApiUrl}/user/login`,
  gm: `${baseApiUrl}/user/gm`,
  pointsByHash: `${baseApiUrl}/user/transaction/points`,
};
export default urls;
