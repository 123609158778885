import { Chain, ChainData } from '@dzapio/dzap-sdk';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dzapClient from '../Client';
import { setSrcChainId } from '../pages/Bridge/Store';
import { setChainId } from '../store/reducers/AuthReducer';
import {
  setBridgeDestinationSupportedChainIds,
  setDcaSupportedChainIds,
  setSupportedChainIds,
  setSupportedChainsData,
} from '../store/reducers/ChainsReducer';
import { setPostErrorAction } from '../store/reducers/CommonReducer';
import { PostErrorAction } from '../enums';

function initChains() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  async function fetchAllChains() {
    let allChains: Chain[] = [];
    const supportedChainIds: number[] = [];
    const bridgeDestinationChainIds: number[] = [];
    const dcaSupportedChainIds: number[] = [];
    let supportedChainData: ChainData = {};
    try {
      allChains = await dzapClient.getAllSupportedChains();
      if (Array.isArray(allChains) && allChains.length) {
        allChains.forEach((chain: Chain) => {
          if (chain.supportedAs.source) supportedChainIds.push(Number(chain.chainId));
          if (chain.dcaContract) dcaSupportedChainIds.push(Number(chain.chainId));
          bridgeDestinationChainIds.push(Number(chain.chainId));
          supportedChainData = { ...supportedChainData, [chain.chainId]: chain };
        });
      }
      const chainIdTemp = Number(localStorage.getItem('chainId'));
      if (supportedChainIds.includes(chainIdTemp)) {
        dispatch(setChainId(chainIdTemp));
        dispatch(setSrcChainId(chainIdTemp));
      }
      dispatch(setSupportedChainsData(supportedChainData));
      dispatch(setSupportedChainIds(supportedChainIds));
      dispatch(setBridgeDestinationSupportedChainIds(bridgeDestinationChainIds));
      dispatch(setDcaSupportedChainIds(dcaSupportedChainIds));
      setIsLoading(false);
      dispatch(setPostErrorAction(PostErrorAction.none));
    } catch (e) {
      console.log(e);
      dispatch(setPostErrorAction(PostErrorAction.pageRefresher));
    }
  }
  useEffect(() => {
    fetchAllChains();
  }, []);

  return { isLoading };
}
export default initChains;
