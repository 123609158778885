import { memo } from 'react';
import logo from '../../assets/Logo/dzap.svg';
import SidebarMenu from './SidebarMenu';

const LOGO = (
  <div className="flex items-center justify-center p-4 h-max shrink-0">
    <a className="items-center" href="https://app.dzap.io/">
      <img src={logo} alt="logo" />
    </a>
  </div>
);

const MemoizedSidebarMenu = memo(SidebarMenu);

export default function Sidebar({
  setSidebarOpen,
  sidebarOpen,
}: {
  setSidebarOpen: (open: boolean) => void;
  sidebarOpen: boolean;
}) {
  const closeSidebar = () => {
    setSidebarOpen(false);
  };
  return (
    <>
      {/* Overlay for mobile */}
      {sidebarOpen && (
        <div
          className="fixed inset-0 w-full h-full z-50 bg-gray-900/80 dark:bg-blue-250/80"
          onClick={closeSidebar}
          onKeyDown={(e) => e.key === 'Escape' && closeSidebar()}
          role="button"
          tabIndex={0}
          aria-label="Close Sidebar"
        />
      )}

      {/* Mobile sidebar */}
      {sidebarOpen && (
        <div className="fixed inset-0 w-9/12 h-full overflow-scroll overflow-x-hidden z-50 scroll-container grow-left-to-right-animation bg-white dark:bg-blue-800">
          <div className="flex-1 max-w-x">{LOGO}</div>
          <nav className="flex flex-col flex-1 h-full">
            <SidebarMenu setSidebarOpen={setSidebarOpen} />
          </nav>
        </div>
      )}

      {/* Desktop sidebar */}
      <div className="hidden lg:inset-y-0 lg:flex bg-white dark:bg-backgroundPrimary lg:w-60 lg:flex-col">
        <nav className="flex flex-1 max-h-full overflow-y-auto scroll-container">
          <MemoizedSidebarMenu setSidebarOpen={setSidebarOpen} />
        </nav>
      </div>
    </>
  );
}
