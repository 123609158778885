import { Chain, ChainData } from '@dzapio/dzap-sdk';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  frontierWallet,
  metaMaskWallet,
  okxWallet,
  phantomWallet,
  safeWallet,
  safepalWallet,
  trustWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { Transport, Chain as ViemChains, defineChain, fallback } from 'viem';
import { Config, createConfig } from 'wagmi';
import { injected } from 'wagmi/connectors';
import { AppName } from '../../constants';
import { CustomWagmiChain } from '../../types';
import { generateTransports } from '../../utils/ChainUtils';
import { wcProjectId } from '../AppConfig';
import { allWagmiChains } from './chains';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet,
        walletConnectWallet,
        okxWallet,
        trustWallet,
        frontierWallet,
        safepalWallet,
        phantomWallet,
      ],
    },
  ],
  {
    appName: AppName.DZAP,
    projectId: wcProjectId,
  },
);

export const wagmiChainsById: Record<number, ViemChains> = Object.values(allWagmiChains).reduce((acc, chainData) => {
  return chainData.id
    ? {
        ...acc,
        [chainData.id]: chainData,
      }
    : acc;
}, {});

const getConfiguredWagmiChain = (chainId: number, chainData: Chain): CustomWagmiChain => {
  return {
    ...wagmiChainsById[chainId],

    iconUrl: chainData.logo,
  };
};

export const getAllWagmiChains = (supportedChainIds: number[], supportedChainsData: ChainData) => {
  return supportedChainIds.map((chainId) => getConfiguredWagmiChain(chainId, supportedChainsData[chainId]));
};

const getWagmiTransporters = (supportedChainsData: ChainData) => {
  return Object.values(supportedChainsData).reduce((acc, chainData) => {
    return {
      ...acc,
      [chainData.chainId]: fallback(generateTransports(chainData.rpcProviders)),
    };
  }, {});
};

export const config = ({
  supportedChainIds,
  supportedChainsData,
}: {
  supportedChainIds: number[];
  supportedChainsData: ChainData;
}) => {
  return createConfig({
    chains: getAllWagmiChains(supportedChainIds, supportedChainsData) as [CustomWagmiChain],
    syncConnectedChain: true,
    multiInjectedProviderDiscovery: true,
    connectors: [injected(), ...connectors],
    transports: getWagmiTransporters(supportedChainsData) as Record<number, Transport>,
    /* VIEM CONFIG */
    // client({ chain }) {
    //   const chainId = chain.id as ChainId;
    //   const jsonRpc = JSON_RPC_PROVIDER[chainId];
    //   console.log({ jsonRpc });
    //   return createClient({ chain, transport: jsonRpc ? http(jsonRpc) : http() });
    // },
  }) as Config;
};
