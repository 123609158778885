export const eFormatNativeCurrency = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
export const eFormatNativeCurrencyLowerCase = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee';
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const nativeCurrencyAddresses: string[] = [
  '0x0000000000000000000000000000000000001010',
  ZERO_ADDRESS,
  eFormatNativeCurrency,
  eFormatNativeCurrencyLowerCase,
];

export const dZapNativeTokenFormat = ZERO_ADDRESS;

export const wNativeTokensMapping = {
  WETH_Ethereum: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  WBNB_Bsc: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  WETH_Arbitrum: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  WETH_Optimism: '0x4200000000000000000000000000000000000006',
  WMatic_Polygon: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  WAVAX_Avalanche: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  WETH_Base: '0x4200000000000000000000000000000000000006',
  WETH_Zksync: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
  WTLOS_Telos: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
};

export const nativeWNativePairs: string[] = [...nativeCurrencyAddresses, ...Object.values(wNativeTokensMapping)];
