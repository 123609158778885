import { ApiRpcResponse } from '@dzapio/dzap-sdk';
import { PublicClient, createPublicClient, fallback, http } from 'viem';
import { wagmiChainsById } from '../config/wagmi';
import { getChainRPCProvider } from './ChainUtils';

class ClientProvider {
  private static instance: ClientProvider | null = null;

  private publicClient: PublicClient | null = null;

  private currentChainId: number | null = null;

  private constructor() {
    // private constructor
  }

  public static getInstance(): ClientProvider {
    if (!ClientProvider.instance) {
      ClientProvider.instance = new ClientProvider();
    }
    return ClientProvider.instance;
  }

  public getPublicClient({ chainRpcData, chainId }: { chainRpcData: ApiRpcResponse[]; chainId: number }): PublicClient {
    if (!this.publicClient || chainId !== this.currentChainId) {
      try {
        this.currentChainId = chainId;
        this.publicClient = createPublicClient({
          chain: wagmiChainsById[chainId],
          transport: fallback(
            getChainRPCProvider(chainRpcData).map((rpc) => http(rpc, { batch: { wait: 100 }, retryDelay: 400 })),
          ),
          batch: {
            multicall: true,
          },
        });
      } catch (error) {
        console.log('Error creating viem public client', { error });
        this.publicClient = null as unknown as PublicClient;
      }
    }
    return this.publicClient;
  }
}

export default ClientProvider;
