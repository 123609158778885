interface BridgeSource {
  YOU_PAY: 1;
  YOU_RECEIVE: 2;
}

export const BRIDGE_SOURCE: BridgeSource = {
  YOU_PAY: 1,
  YOU_RECEIVE: 2,
};

interface BatchSwapSource {
  YOU_PAY: 3;
  YOU_RECEIVE: 4;
}

export const BATCH_SWAP_SOURCE: BatchSwapSource = {
  YOU_PAY: 3,
  YOU_RECEIVE: 4,
};

export const UNSUPPORTED_CHAINS_FOR_BRIDGE: number[] = [];
export enum AppName {
  DZAP = 'DZap',
}
