import axios, { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PublicClient } from 'viem';
import { useAccount, useChainId } from 'wagmi';
import dzapClient from '../Client';
import { dZapNativeTokenFormat } from '../config/chain/ChainConfig';
import { DEFAULT_CHAIN_ID, REFERRAL } from '../constants/AppConstants';
import { isNaN, isUndefined } from '../helper/utilityFunctions';
import { setGasPrice } from '../pages/BatchSwap/Store/Reducer';
import { setSrcChainId } from '../pages/Bridge/Store';
import { RootState } from '../store';
import {
  setAccount,
  setChainId,
  setIsNativeCurrencyInfoLoading,
  setNativeCurrencyInfo,
  setReferralLink,
  setUserInfo,
} from '../store/reducers/AuthReducer';
import { setCurrentChainInfo } from '../store/reducers/ChainsReducer';
import { setNetworkFee } from '../store/reducers/CommonReducer';
import { isSupportedChain } from '../utils/ChainUtils';
import { getPublicClient } from '../utils/ContractUtils';
import { currencyFormatter } from '../utils/GeneralUtils';
import fetchGasPrice from '../utils/fetchGasPrice';
import { getCurrentAppBaseUrl, getParams } from '../utils/AppUtils';
import { userLogin } from '../services/user/login';
import { UserDetails } from '../pages/Rewards/types/user';

function initAuth() {
  const { chainId, account: authAccount } = useSelector(
    (state: RootState) => state.auth,
    (prev, next) => prev.chainId === next.chainId && prev.account === next.account,
  );
  const { supportedChainIds, supportedChainsData } = useSelector(
    (state: RootState) => state.chains,
    (prev, next) =>
      prev.supportedChainIds === next.supportedChainIds && prev.supportedChainsData === next.supportedChainsData,
  );
  const dispatch = useDispatch();
  const walletChainId = useChainId();
  const account = useAccount();
  const initAuthChainId = !isUndefined(account.address) ? walletChainId : chainId;

  const updateAccount = (wagmiAccount?: string) => {
    if (wagmiAccount) {
      localStorage.setItem('account', wagmiAccount);
    } else {
      localStorage.removeItem('account');
    }
    dispatch(setAccount(wagmiAccount ?? ''));
  };
  const initChainId = () => {
    if (isNaN(initAuthChainId)) return;
    localStorage.setItem('chainId', initAuthChainId.toString());
  };
  const setBalance = async (publicClient: PublicClient) => {
    dispatch(setIsNativeCurrencyInfoLoading(true));
    try {
      const nativeBalance = +currencyFormatter(
        account?.address ? (await publicClient.getBalance({ address: account.address })).toString() : 0,
      );
      const nativeCurrencyInfo = supportedChainsData[initAuthChainId]?.pricingAvailable
        ? await dzapClient.getTokenPrice([dZapNativeTokenFormat], initAuthChainId)
        : {};
      const nativeQuoteRate =
        nativeCurrencyInfo && nativeCurrencyInfo[dZapNativeTokenFormat]
          ? +nativeCurrencyInfo[dZapNativeTokenFormat]
          : 0;
      const nativeInfo = {
        balance: nativeBalance,
        decimals: 18,
        price: nativeQuoteRate,
      };
      dispatch(setNativeCurrencyInfo(nativeInfo));
      dispatch(setIsNativeCurrencyInfoLoading(false));
    } catch (error: any) {
      dispatch(setIsNativeCurrencyInfoLoading(false));
      if (axios.isAxiosError(error)) {
        // Check if it's an AxiosError
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          // The request was made and the server responded with a status code
          const statusCode = axiosError.response.status;
          console.log(`Request failed with status code ${statusCode}`);
          // Handle the error based on the status code
          switch (statusCode) {
            case 429:
              console.log('Too Many Requests');
              // Handle 429 error here
              break;
            // Add more cases as needed
            default:
              console.log('Unhandled error');
            // Handle other errors here
          }
        } else if (axiosError.request) {
          // The request was made but no response was received
          console.log('No response received');
          // Handle the error in this case
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error setting up the request', axiosError.message);
          // Handle the error in this case
        }
      } else {
        // Handle other types of errors
        console.log('Unknown error:', error);
      }
    }
  };

  const login = async () => {
    try {
      if (!account.address) {
        dispatch(setUserInfo({} as UserDetails));
        return;
      }
      const referralId = getParams(REFERRAL);
      const response = await userLogin(account.address, referralId);
      if (response?.status === 'success') {
        dispatch(setUserInfo(response?.user));
        dispatch(setReferralLink(`${getCurrentAppBaseUrl}/swap?${REFERRAL}=${response.user.referralCode}`));
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    updateAccount(account?.address);
    login();
    if (account.address) {
      if (account.chain) {
        dispatch(setChainId(account.chain.id));
        dispatch(setSrcChainId(account.chain.id));
        dispatch(setCurrentChainInfo(supportedChainsData[account.chain.id]));
      }
      const chainRpcData = supportedChainsData[initAuthChainId]?.rpcProviders;
      setBalance(getPublicClient({ chainRpcData, chainId: initAuthChainId }));
    } else if (isSupportedChain(supportedChainIds, initAuthChainId)) {
      dispatch(setChainId(initAuthChainId));
      dispatch(setSrcChainId(initAuthChainId));

      dispatch(setCurrentChainInfo(supportedChainsData[initAuthChainId]));
    } else {
      dispatch(setChainId(DEFAULT_CHAIN_ID));
      dispatch(setSrcChainId(DEFAULT_CHAIN_ID));
      dispatch(setCurrentChainInfo(supportedChainsData[DEFAULT_CHAIN_ID]));
    }
  }, [account.address, account.chain]);

  useEffect(() => {
    initChainId();
    const chainRpcData = supportedChainsData[initAuthChainId]?.rpcProviders;
    if (!authAccount) setBalance(getPublicClient({ chainRpcData, chainId: initAuthChainId }));
    fetchGasPrice({ chainId, chainRpcData }).then((gasPrice) => {
      if (gasPrice !== null) {
        dispatch(setGasPrice(BigInt(gasPrice.average)));
        dispatch(setNetworkFee(gasPrice));
      }
    });
  }, [initAuthChainId]);
}
export default initAuth;
