import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Chain, ChainData } from '@dzapio/dzap-sdk';

export type ChainsReducer = {
  supportedChainsData: ChainData;
  currentChainInfo: Chain;
  supportedChainIds: number[];
  dcaSupportedChainIds: number[];
  bridgeDestinationSupportedChainIds: number[];
};

const initialState: ChainsReducer = {
  supportedChainsData: [],
  supportedChainIds: [],
  dcaSupportedChainIds: [],
  bridgeDestinationSupportedChainIds: [],
  currentChainInfo: {
    chainId: 0,
    name: '',
    chainType: '',
    coin: '',
    coinKey: '',
    dcaContract: '',
    logo: '',
    multicallAddress: '',
    pricingAvailable: true,
    balanceAvailable: true,
    swapBridgeContract: '',
    tokenlistUrl: '',
    rpcProviders: [],
    blockExplorerUrl: '',
    nativeToken: {
      balance: '0',
      name: '',
      symbol: '',
      contract: '',
      decimals: 0,
    },
    supportedAs: {
      source: false,
      destination: false,
    },
  },
};

export const chains = createSlice({
  name: 'chains',
  initialState,
  reducers: {
    setSupportedChainsData: (state, action: PayloadAction<ChainData>) => {
      const currentState = state;
      currentState.supportedChainsData = action.payload;
    },
    setSupportedChainIds: (state, action: PayloadAction<number[]>) => {
      const currentState = state;
      currentState.supportedChainIds = action.payload;
    },
    setDcaSupportedChainIds: (state, action: PayloadAction<number[]>) => {
      const currentState = state;
      currentState.dcaSupportedChainIds = action.payload;
    },
    setBridgeDestinationSupportedChainIds: (state, action: PayloadAction<number[]>) => {
      const currentState = state;
      currentState.bridgeDestinationSupportedChainIds = action.payload;
    },
    setCurrentChainInfo: (state, action: PayloadAction<Chain>) => {
      const currentState = state;
      currentState.currentChainInfo = action.payload;
    },
  },
});

export const {
  setSupportedChainsData,
  setSupportedChainIds,
  setDcaSupportedChainIds,
  setBridgeDestinationSupportedChainIds,
  setCurrentChainInfo,
} = chains.actions;

export default chains.reducer;
