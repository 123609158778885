import { useDispatch, useSelector } from 'react-redux';
import '../../assets/Css/Chain/index.scss';
import { FEATURES } from '../../constants/AppConstants';
import { Features } from '../../enums';
import { RootState } from '../../store';
import { setIsChainSwitcher } from '../../store/reducers/AuthReducer';
import { isSupportedChain } from '../../utils/ChainUtils';
import ChainListItem from './ChainListItem';

export default function ChainListModal() {
  const { requestedChainId, account, chainId: authChainId } = useSelector((state: RootState) => state.auth);
  const { supportedChainIds, dcaSupportedChainIds, supportedChainsData } = useSelector(
    (state: RootState) => state.chains,
  );
  const dispatch = useDispatch();
  const handleClose = (e: React.SyntheticEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(setIsChainSwitcher(false));
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleClose(e);
    } else if (e.key === 'Escape') {
      handleClose(e);
    }
  };

  return (
    <div
      onKeyDown={handleKeyPress}
      role="button"
      tabIndex={0}
      onClick={handleClose}
      className="fixed inset-0 flex items-center justify-center dark:bg-black/45 bg-opacity-[10px] z-50 backdrop-blur-sm"
    >
      <div className="w-full p-4 m-4  md:w-[524px] lg:m-0 modalFadeIn bg-white dark:bg-blue-900 rounded-xl h-max">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between w-full">
            <p
              className={`w-full text-base font-semibold text-blue-100 dark:text-gray-300 font-inter ${!account && 'p-2'}`}
            >
              Switch Networks
            </p>
            <div className="flex items-center justify-end w-full text-base font-bold text-blue-100 dark:text-white">
              <button type="button" onClick={() => handleClose}>
                &#x2715;
              </button>
            </div>
          </div>
          {account && (
            <p className="py-2 text-sm font-normal text-gray-2050 dark:text-gray-500 font-inter">
              You are currently connected to the{' '}
              <b className="text-blue-800 dark:text-white">
                {supportedChainsData[authChainId]?.name || 'Unknown Network'}
              </b>
              Network
            </p>
          )}
        </div>

        {account && (
          <span className="flex justify-end py-2 text-sm font-normal text-gray-1000 font-inter">Available on</span>
        )}
        <div className="flex flex-col gap-4 h-[400px] overflow-y-auto">
          {supportedChainIds.map((chainId: number) => {
            const isActive = chainId === authChainId;
            const features = [FEATURES[Features.batchSwap], FEATURES[Features.bridge]];
            if (isSupportedChain(dcaSupportedChainIds, chainId)) {
              features.push(FEATURES[Features.dca]);
            }
            return (
              <ChainListItem
                key={chainId}
                chainId={chainId}
                features={features}
                connectedChainId={authChainId}
                isActive={isActive}
                requestedChainId={requestedChainId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
