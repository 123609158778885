/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
// @ts-nocheck
import { LoadingOutlined } from '@ant-design/icons';

declare const ButtonHTMLTypes: ['submit', 'button', 'reset'];
export declare type ButtonHTMLType = (typeof ButtonHTMLTypes)[number];

interface ButtonProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  disabled?: boolean;
  children: React.ReactNode;
  loading?: boolean;
  className?: string;
  isSubmit?: boolean;
}

function Button({
  onClick = null,
  disabled = false,
  children,
  loading = false,
  className = '',
  isSubmit = false,
}: ButtonProps) {
  return (
    <button
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
      disabled={disabled || loading}
      className={className}
    >
      {loading && <LoadingOutlined className="text-white mr-1" spin />}
      {children}
    </button>
  );
}

export default Button;
