import { ApiRpcResponse } from '@dzapio/dzap-sdk';
import { createPublicClient, fallback } from 'viem';
import { FeeHistory } from 'viem/_types/types/fee';
import { wagmiChainsById } from '../config/wagmi';
import { ChainIdsWithNoFeeHistory } from '../constants/chains';
import { FormattedFeeHistory } from '../types';
import { generateTransports } from './ChainUtils';

const meanGasFee = (arr: number[]) => {
  const sum = arr.reduce((a: number, b: number) => a + b);
  return Math.round(sum / arr.length);
};

const formatFeeHistory = (result: FeeHistory): FormattedFeeHistory[] => {
  try {
    let blockNumber = Number(result.oldestBlock);
    let index = 0;

    const formattedFeeHistory: FormattedFeeHistory[] = [];

    const { oldestBlock, reward, baseFeePerGas, gasUsedRatio } = result;
    const loopLen = Number(oldestBlock) + (reward?.length || 0);

    while (blockNumber < loopLen) {
      formattedFeeHistory.push({
        blockNumber,
        baseFeePerGas: Number(baseFeePerGas[index]),
        gasUsedRatio: Number(gasUsedRatio[index]),
        priorityFeePerGas: reward ? reward[index].map((x: bigint) => Number(x)) : [],
      });
      blockNumber += 1;
      index += 1;
    }

    return formattedFeeHistory;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

async function fetchGasPrice({ chainRpcData, chainId }: { chainRpcData: ApiRpcResponse[]; chainId: number }): Promise<{
  slow: number;
  average: number;
  fast: number;
  rapid: number;
} | null> {
  try {
    const viemChain = wagmiChainsById[chainId];
    if (viemChain) {
      const viemClient = createPublicClient({
        chain: viemChain,
        transport: fallback(generateTransports(chainRpcData)),
      });
      if (ChainIdsWithNoFeeHistory.includes(chainId)) {
        if ('getGasPrice' in viemClient && typeof viemClient.getGasPrice === 'function') {
          const result = await viemClient.getGasPrice();
          return {
            slow: Number(result),
            average: Number(result),
            fast: Number(result),
            rapid: Number(result),
          };
        }
      }
      if ('getFeeHistory' in viemClient && typeof viemClient.getFeeHistory === 'function') {
        const result: FeeHistory = await viemClient.getFeeHistory({
          blockCount: 10,
          blockTag: 'latest',
          rewardPercentiles: [25, 50, 75, 99],
        });

        if ('getBlock' in viemClient && typeof viemClient.getBlock === 'function') {
          const block = await viemClient.getBlock({
            blockTag: 'pending',
          });
          const formattedFeeHistory = formatFeeHistory(result);
          const { baseFeePerGas } = block;
          const slowIndex = 0;
          const avgIndex = 1;
          const fastIndex = 2;
          const rapidIndex = 3;
          const slow = meanGasFee(formattedFeeHistory.map((item) => item.priorityFeePerGas[slowIndex]));
          const average = meanGasFee(formattedFeeHistory.map((item) => item.priorityFeePerGas[avgIndex]));
          const fast = meanGasFee(formattedFeeHistory.map((item) => item.priorityFeePerGas[fastIndex]));
          const rapid = meanGasFee(formattedFeeHistory.map((item) => item.priorityFeePerGas[rapidIndex]));
          return {
            slow: slow + Number(baseFeePerGas),
            average: average + Number(baseFeePerGas),
            fast: fast + Number(baseFeePerGas),
            rapid: rapid + Number(baseFeePerGas),
          };
        }
      }
      return null;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export default fetchGasPrice;
