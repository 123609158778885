import { ReactNode, Component } from 'react';
import { useSelector } from 'react-redux';
import { PostErrorAction } from './enums';
import { RootState } from './store';
import PageRefresher from './views/Popup/PageRefresher';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

class ErrorBoundaryClass extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.log(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <PageRefresher />;
    }

    return children;
  }
}

const ErrorBoundary = ({ children }: { children: ReactNode }) => {
  const { postErrorAction } = useSelector((state: RootState) => state.common);
  const getErrorBoundaryAction = () => {
    switch (postErrorAction) {
      case PostErrorAction.pageRefresher:
        return <PageRefresher />;
      default:
        return children;
    }
  };
  return <ErrorBoundaryClass>{getErrorBoundaryAction()}</ErrorBoundaryClass>;
};

export default ErrorBoundary;
