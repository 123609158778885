import { AppEnv, Features } from '../../enums';

const { NODE_ENV, REACT_APP_ENV, REACT_APP_SLACK_WEBHOOK_URL, REACT_APP_ENABLE_THEME, REACT_APP_BASE_API_URL } =
  process.env;

export const INTEGRATOR_NAME = 'dzap';
export const INTEGRATOR_ADDRESS = '0x45dd5ec28dbf5296dffae428d7f484ab02ee7bad'; // dzapProjectId

let baseApiUrl = REACT_APP_BASE_API_URL || 'https://api.dzap.io';
let stagingUrl = 'https://staging.dzap.io';
let localhost = 'http://localhost:8080';
const versionPostfix = 'v1';
export const dZapDataSyncUrl = 'https://dzap-data-sync-zmgbcuj6va-lz.a.run.app/';
export const isDevelopment = NODE_ENV === 'development';
export const appEnv = REACT_APP_ENV || AppEnv.development;
export const isProd = appEnv === AppEnv.production;
export const isThemeEnabled = REACT_APP_ENABLE_THEME === 'true';
export const slackWebhookUrl = isDevelopment
  ? ''
  : REACT_APP_SLACK_WEBHOOK_URL || 'https://hooks.slack.com/services/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';

if (!isProd) {
  baseApiUrl = stagingUrl;
}

export default `${baseApiUrl}/${versionPostfix}`;
export const decimalPlaces = 3;
export const services = {
  oneInch: 'oneInch',
  dZap: 'dzap',
  unmarshal: 'unmarshal',
};

export const wcProjectId = 'b5f3b5acddd07b50fe8d2f223ffe960b';

export const dZapVersion = process.env.REACT_APP_DE_ZAP_VERSION || 'v2';

export const dcaVersion = +parseFloat(process.env.REACT_APP_DE_ZAP_VERSION || '1');

export const batchSwapProjectId = parseInt(process.env.REACT_APP_BATCH_SWAP_PROJECT_ID || '0', 10);

export const GRAPH_API: { [key: number]: string } = {
  137: 'https://subgraph.satsuma-prod.com/1e94c71d7f99/shivam-s-team/dzap-dca-polygon/api',
  42161: 'https://subgraph.satsuma-prod.com/1e94c71d7f99/shivam-s-team/dca-arbitrum/api',
  10: 'https://subgraph.satsuma-prod.com/1e94c71d7f99/shivam-s-team/dca-optimism/api',
  324: 'https://api.studio.thegraph.com/query/62261/dzap-dca-zksync/version/latest',
  8453: 'https://subgraph.satsuma-prod.com/1e94c71d7f99/shivam-s-team/dca-base/version/api',
  40: 'https://api.goldsky.com/api/public/project_clxq1vu3ner0c01t87mqs9jjz/subgraphs/dzap-dca-telos/0.0.1/gn',
};

export const MAX_DECIMAL_PLACES = 18;
