import { BatchSwapFormTokens } from '../types';

export const DZAP_PROVIDERS = {
  xyFinance: {
    name: 'XY Finance',
    key: 'xyFinance',
  },
};

export const DZAP_FEE = {
  title: 'DZap Fee',
  amount: '0.1',
  text: '% Free',
};
export const NETWORK_PERCENT = {
  amount: '~ $36.786',
  text: 'Network Fee',
};
export const REVIEW_SCREEN_HEADER = {
  text: 'Review Order',
  navigation: 'Back',
};
export const TOKENS_MANAGE_MODAL_INPUT_PLACEHOLDER = 'Search Token Name or Paste Address';
export const TOKENS_MANAGE_MODAL_BUTTON = 'Confirm';
export const MODAL_TYPES = {
  addToken: 'ADD_TOKEN',
  approval: 'APPROVAL',
  trxProcess: 'TRX_PROCESS',
  trxComplete: 'TRX_COMPLETE',
};
export const EMPTY_STRING = '';

export const BATCH_SWAP_FROM_TOKENS = 'batchSwapFromTokens';
export const BATCH_SWAP_TO_TOKENS = 'batchSwapToTokens';
export const FIELD_AMOUNT = 'amount';
export const FIELD_PERCENT = 'percent';

export const BATCH_SWAP_SLIDER_STEPS = 0.1;
export const BATCH_SWAP_TRX_STEPS = {
  prepare: 'Prepare',
  approving: 'Approve',
  swap: 'Swap',
};

export const MULTI_SWAP_EVENT = 'MultiSwapped';
export const FROM_TOKEN_SIZE = 3;
export const TO_TOKEN_SIZE = 3;

// upto 20 0's
export const MAX_SWAP_AMT = 100000000000000000000.0;

export const LS_KEY_BATCH_SWAP_TOKENS = 'batchSwapTokens';
export const LS_KEY_BATCH_BUY_TOKENS = 'batchBuyTokens';
export const LS_KEY_ACTION = 'action';
export const DEBOUNCE_TIME = 500;
export const REFRESH_TIME = 30 * 1000;
export const DEFAULT_QUOTE_RATE_AMT = '0';

export const DEFAULT_BATCH_BUY_TOKENS: { [key: number]: BatchSwapFormTokens } = {
  137: {
    from: [
      {
        contract: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
        percent: 100,
        amount: '0',
      },
    ],
  },
  56: {
    from: [
      {
        contract: '0x55d398326f99059fF775485246999027B3197955',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
        percent: 100,
        amount: '0',
      },
    ],
  },
  1: {
    from: [
      {
        contract: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        percent: 100,
        amount: '0',
      },
    ],
  },
  42161: {
    from: [
      {
        contract: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        percent: 100,
        amount: '0',
      },
    ],
  },
  324: {
    from: [
      {
        contract: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
        percent: 100,
        amount: '0',
      },
    ],
  },
  10: {
    from: [
      {
        contract: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
        percent: 100,
        amount: '0',
      },
    ],
  },
  169: {
    from: [
      {
        contract: '0xb73603C5d87fA094B7314C74ACE2e64D165016fb',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0xEc901DA9c68E90798BbBb74c11406A32A70652C3',
        percent: 100,
        amount: '0',
      },
    ],
  },
  8453: {
    from: [
      {
        contract: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
        percent: 100,
        amount: '0',
      },
    ],
  },
  43114: {
    from: [
      {
        contract: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
        percent: 100,
        amount: '0',
      },
    ],
  },
  40: {
    from: [
      {
        contract: '0x0000000000000000000000000000000000000000',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x975Ed13fa16857E83e7C493C7741D556eaaD4A3f',
        percent: 100,
        amount: '0',
      },
    ],
  },
  30: {
    from: [
      {
        contract: '0xef213441A85dF4d7ACbDaE0Cf78004e1E486bB96',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x0000000000000000000000000000000000000000',
        percent: 100,
        amount: '0',
      },
    ],
  },
  1116: {
    from: [
      {
        contract: '0x0000000000000000000000000000000000000000',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1',
        percent: 100,
        amount: '0',
      },
    ],
  },
  196: {
    from: [
      {
        contract: '0x0000000000000000000000000000000000000000',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x1E4a5963aBFD975d8c9021ce480b42188849D41d',
        percent: 100,
        amount: '0',
      },
    ],
  },
  534352: {
    from: [
      {
        contract: '0x0000000000000000000000000000000000000000',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4',
        percent: 100,
        amount: '0',
      },
    ],
  },
  59144: {
    from: [
      {
        contract: '0xA219439258ca9da29E9Cc4cE5596924745e12B93',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x176211869cA2b568f2A7D4EE941E073a821EE1ff',
        percent: 100,
        amount: '0',
      },
    ],
  },
  5000: {
    from: [
      {
        contract: '0x0000000000000000000000000000000000000000',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
        percent: 100,
        amount: '0',
      },
    ],
  },
  1088: {
    from: [
      {
        contract: '0x0000000000000000000000000000000000000000',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0xbB06DCA3AE6887fAbF931640f67cab3e3a16F4dC',
        percent: 100,
        amount: '0',
      },
    ],
  },
  42220: {
    from: [
      {
        contract: '0x48065fbBE25f71C9282ddf5e1cD6D6A887483D5e',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0x765DE816845861e75A25fCA122bb6898B8B1282a',
        percent: 100,
        amount: '0',
      },
    ],
  },
  1101: {
    from: [
      {
        contract: '0x1E4a5963aBFD975d8c9021ce480b42188849D41d',
        percent: 0,
        amount: '1.0',
      },
    ],
    to: [
      {
        contract: '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035',
        percent: 100,
        amount: '0',
      },
    ],
  },
};

export const BATCH_SWAP_ERRORS: {
  [key: string]: { code: string; message: string };
} = {
  '0x5fabb610': {
    code: 'InvalidInterval',
    message: 'InvalidInterval',
  },
  '0xbf3cad0b': {
    code: 'InvalidMask',
    message: 'InvalidMask',
  },
  '0x5c427cd9': {
    code: 'UnauthorizedCaller',
    message: 'UnauthorizedCaller',
  },
  '0xd92e233d': {
    code: 'ZeroAddress',
    message: 'ZeroAddress',
  },
  '0x52f17a74': {
    code: 'HighPlatformFeeRatio',
    message: 'HighPlatformFeeRatio',
  },
  '0xa020d7b8': {
    code: 'HighFee',
    message: 'HighFee',
  },
  '0xc1ab6dc1': {
    code: 'InvalidToken',
    message: 'The provided token is invalid or not recognized by the DEX.',
  },
  '0x7eab83d4': {
    code: 'InvalidNoOfSwaps',
    message:
      'The requested number of swaps is not valid. This could be because it is below the minimum or above the maximum allowed number of swaps.',
  },
  '0xd57b995a': {
    code: 'InvalidPermitData',
    message: 'Invalid permit data or it may expired. Sign the permit again.',
  },
  '0xddafbaef': {
    code: 'InvalidPermit',
    message:
      'The provided permit signature is not valid. This could be because the signature is not signed correctly or it may expired.',
  },
  '0x947d5a84': {
    code: 'InvalidLength',
    message: 'InvalidLength',
  },
  '0xce5a1e57': {
    code: 'NoAvailableSwap',
    message: 'NoAvailableSwap',
  },
  '0xe346d81d': {
    code: 'InvalidSwapAmount',
    message:
      'The amount to be swapped is not valid. This could be because it is below the minimum or above the maximum allowed swap amount.',
  },
  '0x7f28d711': {
    code: 'InvalidReturnAmount',
    message: 'InvalidReturnAmount',
  },
  '0x03a40bc4': {
    code: 'SwapCallFailed',
    message:
      'The swap call failed and the transaction was reverted. This could be due to an issue with the contract, insufficient funds, or other transaction-related issues.',
  },
  '0x870018d4': {
    code: 'InvalidBlankSwap',
    message: 'InvalidBlankSwap',
  },
  '0xce7e065e': {
    code: 'InvalidPosition',
    message: 'InvalidPosition',
  },
  '0x44e8bd2c': {
    code: 'InvalidNativeAmount',
    message:
      'The provided native token amount is not valid. This could be because it is below the minimum or above the maximum allowed amount.',
  },
  '0x917319ee': {
    code: 'NotWNative',
    message:
      'The provided token is not the WNative token. This token is required for certain operations and cannot be substituted with other tokens.',
  },
  '0xf4b3b1bc': {
    code: 'NativeTransferFailed',
    message:
      'The transfer of native tokens failed. This could be due to insufficient balance or other transfer-related issues.',
  },
  '0x90468ac8': {
    code: 'UnauthorizedTokens',
    message: 'The requested tokens are not authorized for the requested operation.',
  },
  '0x2c5211c6': {
    code: 'InvalidAmount',
    message:
      'The requested amount is not valid. This could be because it is below the minimum or above the maximum allowed amount.',
  },
  '0x6a43f8d1': {
    code: 'InvalidRate',
    message:
      'The exchange rate is not valid. This could be because it is below the minimum or above the maximum allowed rate.',
  },
  '0xaabb9c63': {
    code: 'NoChanges',
    message: 'Changes not found!',
  },
  '0x157144d3': {
    code: 'ZeroSwappedTokens',
    message: 'ZeroSwappedTokens',
  },
};
