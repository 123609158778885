import { ApiRpcResponse } from '@dzapio/dzap-sdk';
import { toHex, trim } from 'viem';
import { http } from 'wagmi';
import { HexString } from '../types';
import { EMPTY_STRING } from '../pages/BatchSwap/Constants';
import { nonEvmChainIdsMap } from '../constants/chains';

export const convertDecimalToHexString = (chainId: number) => trim(toHex(chainId) as HexString);

const AvailableRPCKeys = {
  ALCHEMY_KEY: 'HxHVTDRB9UrZQNXBHC2tigcAAk03i7bb',
  BLASTAPI_KEY: '5f00f6d9-6bd9-493e-a62d-8586ada7f665',
};

export const getChainRPCProvider = (chainRpcData: ApiRpcResponse[]) => {
  const urls = chainRpcData?.map((chainRpc) =>
    chainRpc.keyRequired && chainRpc.keyType && chainRpc.keyType in AvailableRPCKeys
      ? `${chainRpc.url}/${AvailableRPCKeys[chainRpc.keyType]}`
      : chainRpc.url,
  );
  urls.push(EMPTY_STRING);
  return urls;
};

export const generateTransports = (chainRpcData?: ApiRpcResponse[]) => {
  if (!chainRpcData) return [http()];
  const transports = getChainRPCProvider(chainRpcData).map((rpc) => http(rpc));
  return transports;
};

export const getAddressExplorerLink = ({
  blockExplorerUrl,
  address,
}: {
  blockExplorerUrl: string;
  address: string;
}) => {
  return `${blockExplorerUrl}/address/${address}`;
};

export const isSupportedChain = (supportedChainIds: number[], chainId: number) => supportedChainIds.includes(chainId);

export const getTrxExplorerLink = ({ hash, blockExplorerUrl }: { hash: string; blockExplorerUrl: string }) => {
  return `${blockExplorerUrl}/tx/${hash}`;
};

export const isNonEvmChain = (chainId: number) => Object.values(nonEvmChainIdsMap).includes(chainId);
