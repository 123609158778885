import '@rainbow-me/rainbowkit/styles.css';
import { useEffect } from 'react';
import Providers from './Providers';
import initChains from './hooks/initChains';
import { cacheAppVersion, getAppVersion, getCachedAppVersion } from './utils/AppUtils';
import InvalidPage from './views/Popup/InvalidPage';
import ErrorBoundary from './ErrorBoundary';

const appVersion = getAppVersion();
function App() {
  const { isLoading } = initChains();
  useEffect(() => {
    if (getCachedAppVersion() !== appVersion) {
      localStorage.clear();
      cacheAppVersion(appVersion);
    }
  }, []);
  return (
    <ErrorBoundary>
      <InvalidPage />
      {!isLoading && <Providers />}
    </ErrorBoundary>
  );
}

export default App;
