import { Tooltip } from 'antd';
import { ReactNode, KeyboardEvent } from 'react';
import tooltipInfo from '../../assets/Icons/tooltip-info.svg';

interface HoverInfoTipProps {
  title?: string;
  desc: string;
  placement?:
    | 'topLeft'
    | 'top'
    | 'topRight'
    | 'leftTop'
    | 'left'
    | 'leftBottom'
    | 'rightTop'
    | 'right'
    | 'rightBottom'
    | 'bottomLeft'
    | 'bottom'
    | 'bottomRight';
  children: ReactNode;
  underline?: boolean;
  handleOpenExplorePage?: () => void;
}

function HoverInfoTip({
  title,
  desc,
  placement,
  children,
  underline = true,

  handleOpenExplorePage = () => {},
}: HoverInfoTipProps) {
  const handleClick = () => {
    if (handleOpenExplorePage) {
      handleOpenExplorePage();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter' && handleOpenExplorePage) {
      handleOpenExplorePage();
    }
  };

  return (
    <Tooltip
      placement={placement}
      color="#0A0B18"
      title={
        <div>
          {title && (
            <div className="flex items-center gap-2 mb-1 text-sm font-medium text-normal">
              <img src={tooltipInfo} className="w-3 h-3" alt="info" />
              <p className="tooltip_info_title">{title}</p>
            </div>
          )}
          <span
            onClick={handleClick}
            onKeyDown={handleKeyDown}
            role="button"
            tabIndex={0}
            className="text-xs text-center cursor-pointer md:text-sm back font-inter text-white dark:text-gray-500"
          >
            {desc}
          </span>
        </div>
      }
    >
      <span className={underline ? 'border-dashed cursor-pointer border-b border-gray-600' : 'cursor-pointer'}>
        {children}
      </span>
    </Tooltip>
  );
}

export default HoverInfoTip;
