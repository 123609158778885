import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';

function MainAppLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="fixed flex flex-col w-full h-screen text-gray-500 bg-gray-400 dark:bg-backgroundPrimary lg:bg-none">
      <Header setSidebarOpen={setSidebarOpen} />
      <div className="flex flex-1 w-full h-full overflow-auto ">
        <Sidebar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
        <div className="relative z-10 flex items-center justify-center w-full h-full gap-8 overflow-y-auto  md:z-0  md:bg-center md:bg-no-repeat md:bg-cover md:border-gray700 dark:md:bg-hero-pattern dark:md:bg-background  modalFadeIn scroll-container ">
          <Outlet />
          {/* <Banner /> */}
        </div>
      </div>
      <div className="block green-gradient-circle-bottom-right-mobile lg:hidden md:hidden xl:hidden 2xl:hidden" />
      <div className="block green-gradient-circle-top-left-mobile lg:hidden md:hidden xl:hidden 2xl:hidden" />
    </div>
  );
}
export default MainAppLayout;
