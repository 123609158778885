/* eslint-disable */
import { getAddress, isAddress, parseUnits } from 'viem';
import { formatUnits, isUndefined } from '.././helper/utilityFunctions';
import logoAlternateToken from '../assets/Images/dezap-logo.svg';
import { nativeCurrencyAddresses, nativeWNativePairs } from '../config/chain/ChainConfig';
import { TokenInfo } from '../types';
import { dissolveExponentialNotation, numberToString } from './NumberUtils';

export const camelCaseToSpaces = (str: string): string =>
  str
    .replace(/([A-Z][a-z0-9]+)/g, ' $1 ')
    .replace(/\s{2}/g, ' ')
    .trim();

export const stringValidation = {
  isNotEmpty(str: string) {
    const pattern = /\S+/;
    return pattern.test(str);
  },
  isNumber(str: string) {
    const pattern = /^\d+\.?\d*$/;
    return pattern.test(str);
  },
  isSame(str1: string, str2: string) {
    return str1 === str2;
  },
};
export const formatDecimalsUpto = (number: number, decimals: number) => {
  let formattedNumber = number;
  const newDecimal = 10 ** decimals;
  const numArray = formattedNumber?.toString().split('.');
  let decimalPlaces = 0;
  if (typeof numArray[1] !== 'undefined') {
    decimalPlaces = numArray[1].length;
  }
  if (decimalPlaces > decimals) {
    formattedNumber *= newDecimal;
    formattedNumber = Math.trunc(number);
    formattedNumber /= newDecimal;
    return formattedNumber;
  }
  return formattedNumber;
};

export const truncateDecimals = (value: number | string, index?: number) => {
  const number = dissolveExponentialNotation(value);
  const stringValue = number?.toString();

  if (!stringValue || stringValue.indexOf('.') === -1) {
    return number;
  }

  let totalLength: number;

  if (!isUndefined(index)) {
    totalLength = stringValue.indexOf('.') + 1 + index;
  } else {
    const regex = /\.0*([1-9]?[0-9]?[0-9]?[0-9]?)/;
    const match = stringValue.match(regex);
    if (match) {
      totalLength = stringValue.indexOf('.') + match[0].length;
    } else {
      totalLength = stringValue.indexOf('.') + 1;
    }
  }

  return stringValue.slice(0, totalLength);
};

export function truncateNumber(number: number | string, digits: number = 10) {
  const numStr = numberToString(number);
  const [whole, decimal] = numStr.split('.');
  if (!decimal || +numStr <= 0) return whole;

  const firstNonZeroIndex = decimal.search(/[1-9]/);

  if (firstNonZeroIndex === -1 || (+whole > 0 && firstNonZeroIndex > 4)) {
    return whole;
  }

  const sliceTo = firstNonZeroIndex < 4 ? 4 : firstNonZeroIndex <= digits ? firstNonZeroIndex + 1 : digits + 1;
  return `${whole}.${decimal.slice(0, sliceTo)}`;
}

export const abbreviateNumber = (num: number, digits = 4) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((data) => num >= data.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : truncateDecimals(num, digits);
};

// Converts small unit to big unit => Like WEI to ETH -> 1000000 => 1 USDT, returns a String

export const currencyFormatter = (value: number | string | bigint, decimals = 18) => {
  try {
    return !value ? Number(value) : truncateDecimals(formatUnits(value, decimals));
  } catch (e) {
    return parseFloat(value as string) / 10 ** decimals;
  }
};
// Converts small unit to big unit => Like WEI to ETH -> 1000000 => 1 USDT, returns a String
export const abbreviateCurrency = (value: number | string, decimals: number, truncateDigits = 4) =>
  abbreviateNumber(currencyFormatter(value, decimals), truncateDigits);

export const parseCurrencyToBigInt = (valueString: string | number, decimal: number) =>
  parseUnits(valueString?.toString(), decimal);

export const isNumber = (input: string) => /^-?[\d.]+(?:e-?\d+)?$/.test(input);

export const getAlternateTokenIcon = () => logoAlternateToken;

export const getAddressChecksum = (address: string) => (isAddress(address) ? getAddress(address) : address);

export const parseJsonString = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

export const formatSecondsToDHM = (seconds: number) => {
  const day = Math.floor(seconds / (3600 * 24));
  const hour = Math.floor((seconds % (3600 * 24)) / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  const dDisplay = day > 0 ? day + (day === 1 ? ' day, ' : ' days, ') : '';
  const hDisplay = hour > 0 ? hour + (hour === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay = minute > 0 ? minute + (minute === 1 ? ' min, ' : ' mins, ') : '';
  const outForDisplay = dDisplay + hDisplay + mDisplay;
  return outForDisplay.slice(0, -2);
};

export const getTimestampInSeconds = () => Math.floor(new Date().getTime() / 1000);

export const isNativeCurrency = (contract: string) => nativeCurrencyAddresses.includes(contract);

export const isNativeOrWNativeCurrency = (contract: string) => nativeWNativePairs.includes(contract);

export const addMetamaskAssets = async (
  request: {
    contract: string;
    symbol: string;
    decimals: number;
  }[],
) => {
  try {
    const { ethereum }: any = window;
    request.forEach(async (item) => {
      const wasAdded = await ethereum?.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: item.contract,
            symbol: item.symbol,
            decimals: item.decimals,
          },
        },
      });
      if (wasAdded) {
        console.log('Thanks for your interest!');
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const computeSecondsInDHM = (seconds: number) => {
  const day = Math.floor(seconds / (3600 * 24));
  const hour = Math.floor((seconds % (3600 * 24)) / 3600);
  const minute = Math.floor((seconds % 3600) / 60);
  return {
    day,
    hour,
    minute,
  };
};

export const truncateString = (str: string) =>
  // str?.slice(0, len);
  str;

export const formatGasFee = (estimatedGas: string, nativeTokenPrice = 1) =>
  +currencyFormatter(estimatedGas, 9) * nativeTokenPrice;

type Params = Record<string, string | string[] | number>;

export const paramsSerialiaserForAxiosRequest = (params: Params) => {
  return Object.entries(params)
    .reduce((parts: string[], [key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((val) => parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`));
      } else {
        parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
      return parts;
    }, [])
    .join('&');
};

export function mergeArraysByAddress(array1: TokenInfo[], array2: TokenInfo[]): TokenInfo[] {
  const contractMap = new Map<string, TokenInfo>();
  array1.forEach((obj) => {
    contractMap.set(obj.contract, obj);
  });

  array2.forEach((obj) => {
    if (!contractMap.has(obj.contract)) {
      contractMap.set(obj.contract, obj);
    }
  });

  return Array.from(contractMap.values());
}

export const getPairString = ({
  srcToken,
  srcChainId,
  destToken,
  destChainId,
}: {
  srcToken: string;
  srcChainId: number;
  destToken: string;
  destChainId: number;
}) => {
  return `${srcChainId}_${srcToken}-${destChainId}_${destToken}`;
};

export const safeBigInt = (value: string | number | null | undefined, fallback: bigint = 0n): bigint => {
  try {
    return BigInt(value ?? 0);
  } catch {
    return fallback;
  }
};
