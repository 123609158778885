const PageRefresher = () => {
  return (
    <div className="w-screen text-white h-screen flex items-center justify-center">
      <div className="w-fit">
        Just like the page, it&apos;s okay to be down. You just have to
        <button
          type="button"
          onClick={() => {
            window.location.reload();
          }}
          className="block mt-4 bg-green-100 m-auto p-2 rounded cursor-pointer"
        >
          Retry
        </button>
      </div>
    </div>
  );
};

export default PageRefresher;
