import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { isProd } from './config/AppConfig';
import { APP_ROUTES } from './constants/Links';
import initAuth from './hooks/initAuth';
import MainAppLayout from './views/Layouts/MainAppLayout';
import MissingRootRedirect from './views/Misc/MissingRootRedirect';

const BatchSwapV2Page = React.lazy(() => import('./pages/BatchSwap'));
const CrossChainSwap = React.lazy(() => import('./pages/Bridge'));
const CreateDCAPage = React.lazy(() => import('./pages/CreateDCAPage'));
const DashboardPage = React.lazy(() => import('./pages/Positions'));
const RewardSystem = React.lazy(() => import('./pages/Rewards'));
const ToolsPage = React.lazy(() => import('./pages/Tools/ToolsPage'));

function AppRoutes(): React.ReactElement {
  initAuth();

  return (
    <Routes>
      <Route element={<MainAppLayout />}>
        <Route
          path={APP_ROUTES.batchSwap}
          element={
            <React.Suspense fallback={<>...</>}>
              <BatchSwapV2Page />
            </React.Suspense>
          }
        />
        <Route
          path={APP_ROUTES.bridge}
          element={
            <React.Suspense fallback={<>...</>}>
              <CrossChainSwap />
            </React.Suspense>
          }
        />
        <Route
          path={APP_ROUTES.dca}
          element={
            <React.Suspense fallback={<>...</>}>
              <CreateDCAPage />
            </React.Suspense>
          }
        />
        <Route
          path={APP_ROUTES.dcaPositions}
          element={
            <React.Suspense fallback={<>...</>}>
              <DashboardPage />
            </React.Suspense>
          }
        />
        {!isProd && (
          <Route
            path={APP_ROUTES.rewards}
            element={
              <React.Suspense fallback={<>...</>}>
                <RewardSystem />
              </React.Suspense>
            }
          />
        )}
      </Route>
      <Route
        path="/tools"
        element={
          <React.Suspense fallback={<>...</>}>
            <ToolsPage />
          </React.Suspense>
        }
      />
      <Route path="*" element={<MissingRootRedirect />} />
    </Routes>
  );
}

export default AppRoutes;
