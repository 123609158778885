import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks/useState';
import { RootState } from '../../store/index';
import { setIsInvalidPage } from '../../store/reducers/CommonReducer';

function InvalidPage() {
  const dispatch = useAppDispatch();

  const { isInvalidPage } = useSelector((state: RootState) => state.common);

  const handleClose = useCallback(() => {
    dispatch(setIsInvalidPage(false));
  }, [dispatch]);

  return (
    <Transition.Root show={isInvalidPage} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => dispatch(setIsInvalidPage(false))}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="flex items-center justify-center w-12 h-12 mx-auto text-red-800 bg-red-100 rounded-full">
                    X
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      404
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">You&apos;re beyond the borders</p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleClose}
                  >
                    Go back to Swap
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default InvalidPage;
