import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenResponse } from '../../../types';

type Props = {
  isFromToken: boolean;
  isFormReview: boolean;
  lastSwappedAt: undefined | number;
  dcaTokenList: TokenResponse;
  isDcaTokenListUpdated: boolean;
};
const initialState: Props = {
  isFromToken: true,
  isFormReview: false,
  lastSwappedAt: undefined,
  dcaTokenList: {},
  isDcaTokenListUpdated: false,
};

export const createDCA = createSlice({
  name: 'createDCA',
  initialState,
  reducers: {
    setIsFromToken: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isFromToken = action.payload;
    },
    setIsFormReview: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isFormReview = action.payload;
    },
    setDCATokenList: (state, action: PayloadAction<TokenResponse>) => {
      const currentState = state;
      currentState.dcaTokenList = action.payload;
    },
    setLastSwappedAt: (state, action: PayloadAction<number | undefined>) => {
      const currentState = state;
      currentState.lastSwappedAt = action.payload;
    },
    setIsDcaTokenListUpdated: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isDcaTokenListUpdated = action.payload;
    },
  },
});

export const { setIsFromToken, setIsFormReview, setDCATokenList, setLastSwappedAt, setIsDcaTokenListUpdated } =
  createDCA.actions;

export default createDCA.reducer;
