import projectPackage from '../../package.json';
import { appEnv } from '../config/AppConfig';
import { AppEnv, NetworkIDs, TrxSpeed } from '../enums';
import { HexString } from '../types';
import { getAddressChecksum } from './GeneralUtils';

export const getCachedAccount = () => (localStorage.getItem('account') as HexString) || ('' as HexString);

export const getAppVersion = () => projectPackage.version;

export const getCachedAppVersion = () => localStorage.getItem('version');

export const cacheAppVersion = (version: string) => localStorage.setItem('version', version);

export const isStaging = appEnv === AppEnv.staging;

export const cacheSlippage = (slippage: number) => localStorage.setItem('slippage', slippage.toFixed(2));

export const getCachedSlippage = () => Number(localStorage.getItem('slippage'));

export const cacheTrxSpeed = (speed: string) => localStorage.setItem('trxSpeed', speed);

export const getCachedTrxSpeed = () => localStorage.getItem('trxSpeed') || TrxSpeed.average;

export const isSwapPage = () => window.location.pathname.match('/swap');

export const isBatchSwapPage = () => !!window.location.pathname.match('/swap');

export const isCrossChainSwapPage = () => !!window.location.pathname.match('/bridge');

export const isMemeCoinPage = () => !!window.location.pathname.match('/meme-coin');

export const isDCACreatePage = () => !!window.location.pathname.match('/dca');

export const isDCAPositionsPage = () => !!window.location.pathname.match('/positions');

export const isRewardPage = () => !!window.location.pathname.match('/rewards');

export const isSystemThemeDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;

export const hasDollarValue = (chainId: number) => {
  if (chainId === NetworkIDs.zkSync) {
    return false;
  }
  return true;
};

type Falsy = false | 0 | '' | null | undefined;
function shortenString(str: string) {
  return `${str.substring(0, 6)}...${str.substring(str.length - 4)}`;
}
function shortenAddress(address: string): string {
  try {
    const formattedAddress = getAddressChecksum(address);
    return shortenString(formattedAddress);
  } catch {
    throw new TypeError("Invalid input, address can't be parsed");
  }
}
function shortenIfAddress(address: string | Falsy): string {
  if (typeof address === 'string' && address.length > 0) {
    return shortenAddress(address);
  }
  return '';
}
export const shortAddress = (address: string | undefined) => (address?.length ? shortenIfAddress(address) : '');

export const getParams = (value: string) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  return params.get(value);
};

export const getCurrentAppBaseUrl = window.location.origin;

export function copyToClipboard(text: any) {
  const el = document.createElement('textarea');
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}
