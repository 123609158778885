import { PayloadAction } from '@reduxjs/toolkit';
import { DZAP_PRODUCTION_APP_DOMAIN_HOST, TRACK_PLAUSIBLE_CUSTOM_EVENT } from '../../constants/analytics/constants';
import { getAccountAddress } from '../../constants/AppConstants';
import { ActionPayloadType } from '../../types';
import worker from '../../worker/analytics/analyticWorker';

const analyticsMiddleware = (store: any) => (next: any) => (action: PayloadAction<ActionPayloadType>) => {
  const account = getAccountAddress();
  if (action.type.startsWith('ANALYTICS')) {
    try {
      // * Add account address if available
      const payload = {
        ...action.payload,
        eventData: {
          ...action.payload.eventData,
          ...(account && { account }),
          isProduction: window.location.host === DZAP_PRODUCTION_APP_DOMAIN_HOST,
        },
      };
      // * Send data to the web-worker for processing
      worker.postMessage({
        type: TRACK_PLAUSIBLE_CUSTOM_EVENT,
        ...payload,
        url: window.location.href,
        referrer: document.referrer,
      });
    } catch (err) {
      console.error(err);
    }
  }

  return next(action);
};

export default analyticsMiddleware;
