import { formatUnits as formatter } from 'viem';

type Predicate<T> = (value: T, index: number, array: T[]) => boolean;

export function isNaN(value: any): boolean {
  return typeof value === 'number' && Number.isNaN(value);
}

export function isNull(value: any): value is null | undefined {
  return value === null || value === undefined;
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isUndefined(value: any): value is undefined {
  return typeof value === 'undefined';
}

export function filter<T>(array: T[], predicate: (item: T) => boolean): T[] {
  return array.filter(predicate);
}

export function toLower(str: string): string {
  return str?.toLowerCase();
}

export function isObject(value: any): boolean {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export function findIndex<T>(array: T[], predicate: (item: T) => boolean): number {
  for (let i = 0; i < array.length; i += 1) {
    if (predicate(array[i])) {
      return i;
    }
  }
  return -1;
}

export function isEmpty(value: any): boolean {
  if (value == null) {
    return true;
  }

  if ((Array.isArray(value) || typeof value[Symbol.iterator] === 'function') && value.length === 0) {
    return true;
  }

  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }

  return false;
}

export function isEqual<T extends Record<string, any>>(obj1: T, obj2: T): boolean;
export function isEqual(obj1: string | number, obj2: string | number): boolean;

export function isEqual(obj1: any, obj2: any): boolean {
  if (typeof obj1 === 'string' || typeof obj1 === 'number') {
    return obj1 === obj2;
  }

  const keys1 = obj1 ? (Object.keys(obj1) as Array<keyof typeof obj1>) : [];
  const keys2 = obj2 ? (Object.keys(obj2) as Array<keyof typeof obj2>) : [];

  if (keys1.length !== keys2.length) {
    return false;
  }

  return keys1.every((key) => obj1[key] === obj2[key]);
}

export function startsWith(str: string, target: string, position: number = 0): boolean {
  const { length } = str;

  let adjustedPosition = position < 0 ? 0 : position;
  adjustedPosition = adjustedPosition > length ? length : adjustedPosition;

  const adjustedTarget = target.toString();

  return (
    str.slice(adjustedPosition, adjustedPosition + adjustedTarget.length)?.toLowerCase() ===
    adjustedTarget?.toLowerCase()
  );
}

export function some<T>(array: T[], predicate: Predicate<T>): boolean {
  let index = -1;
  const length = array == null ? 0 : array.length;

  while (index + 1 < length) {
    index += 1;
    if (predicate(array[index], index, array)) {
      return true;
    }
  }

  return false;
}

export function gt(a: bigint | string | number, b: bigint | string | number): boolean {
  return BigInt(a) > BigInt(b);
}

export function lt(a: bigint | string | number, b: bigint | string | number): boolean {
  return BigInt(a) < BigInt(b);
}

export function gte(a: bigint | string | number, b: bigint | string | number): boolean {
  return BigInt(a) >= BigInt(b);
}

export function lte(a: bigint | string | number, b: bigint | string | number): boolean {
  return BigInt(a) <= BigInt(b);
}
export function eq(a: bigint | string | number, b: bigint | string | number): boolean {
  return BigInt(a) === BigInt(b);
}

export function neq(a: bigint | string | number, b: bigint | string | number): boolean {
  return BigInt(a) !== BigInt(b);
}

export function add(a: bigint | string | number, b: bigint | string | number): bigint {
  return BigInt(a) + BigInt(b);
}

export function subtract(a: bigint | string | number, b: bigint | string | number): bigint {
  return BigInt(a) - BigInt(b);
}

export function multiply(a: bigint | string | number, b: bigint | string | number): bigint {
  return BigInt(a) * BigInt(b);
}

export function div(a: bigint | string | number, b: bigint | string | number): bigint {
  if (gt(b, 0) === false) throw new Error('Division by zero');
  return BigInt(a) / BigInt(b);
}
export function formatUnits(value: bigint | string | number, decimals: number = 18): string {
  return formatter(BigInt(value), decimals);
}
