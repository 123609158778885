import axios from 'axios';
import urls from '../../config/urls';
import { UserDetails } from '../../pages/Rewards/types/user';

export const userLogin = async (account: string, referralCode: string | null) => {
  try {
    const response = await axios.post(urls.userLogin, {
      account,
      referralCode,
    });
    return response.data as {
      status: string;
      user: UserDetails;
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
