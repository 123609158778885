export const SWAP = {
  PAY: {
    SWAP_PAY_MODAL_OPEN_EVENT: 'SWAP_PAY_MODAL_OPEN_EVENT',
    SWAP_PAY_MODAL_CLOSE_EVENT: 'SWAP_PAY_MODAL_CLOSE_EVENT',
    SWAP_PAY_INPUT_EVENT: 'SWAP_PAY_INPUT_EVENT',
    SWAP_PAY_TOKEN_REMOVE_EVENT: 'SWAP_PAY_TOKEN_REMOVE_EVENT',
  },
  RECIEVE: {
    SWAP_RECIEVE_MODAL_OPEN_EVENT: 'SWAP_RECIEVE_MODAL_OPEN_EVENT',
    SWAP_RECIEVE_MODAL_CLOSE_EVENT: 'SWAP_RECIEVE_MODAL_CLOSE_EVENT',
    SWAP_RECIEVE_TOKEN_REMOVE_EVENT: 'SWAP_RECIEVE_TOKEN_REMOVE_EVENT',
  },
  SLIPPAGE: {
    SLIPPAGE_MODAL_OPEN_EVENT: 'SWAP_SLIPPAGE_MODAL_OPEN_EVENT',
    SLIPPAGE_MODAL_CLOSE_EVENT: 'SWAP_SLIPPAGE_MODAL_CLOSE_EVENT',
    SLIPPAGE_TOLERANCE_CHANGE_EVENT: 'SWAP_SLIPPAGE_TOLERANCE_CHANGE_EVENT',
    SLIPPAGE_GAS_PRICE_CHANGE_EVENT: 'SWAP_SLIPPAGE_GAS_PRICE_CHANGE_EVENT',
  },
  SWAP_REFRESH_QOUTE_EVENT: 'SWAP_REFRESH_QOUTE_EVENT',
  SWAP_DETAILS_EVENT: 'SWAP_DETAILS_EVENT',
  SWAP_ROUTE_SELECT_EVENT: 'SWAP_ROUTE_SELECT_EVENT',
  SWAP_PAY_RECIEVE_TOGGLE: 'SWAP_PAY_RECIEVE_TOGGLE',
  SWAP_REVIEW_EVENT: 'SWAP_REVIEW_EVENT',

  SWAP_WALLET_APPROVAL_INITIATED_EVENT: 'SWAP_WALLET_APPROVAL_INITIATED_EVENT', // done
  SWAP_WALLET_APPROVAL_SUCCESS_EVENT: 'SWAP_WALLET_APPROVAL_SUCCESS_EVENT', // done
  SWAP_WALLET_APPROVAL_REJECT_EVENT: 'SWAP_WALLET_APPROVAL_REJECT_EVENT', // done
  SWAP_WALLET_APPROVAL_FAILED_EVENT: 'SWAP_WALLET_APPROVAL_FAILED_EVENT', // done

  SWAP_INITIATED_EVENT: 'SWAP_INITIATED_EVENT',
  SWAP_ROUTE_EVENT: 'SWAP_ROUTE_EVENT',
  SWAP_WALLET_TXN_REJECT_EVENT: 'SWAP_WALLET_TXN_REJECT_EVENT',
  SWAP_RETRY_EVENT: 'SWAP_RETRY_EVENT', // done
  SWAP_SUCCESS_EVENT: 'SWAP_SUCCESS_EVENT',
  SWAP_FAILED_EVENT: 'SWAP_FAILED_EVENT',
};

export const BRIDGE = {
  PAY: {
    BRIDGE_PAY_MODAL_OPEN_EVENT: 'BRIDGE_PAY_MODAL_OPEN_EVENT', // done
    BRIDGE_PAY_MODAL_CLOSE_EVENT: 'BRIDGE_PAY_MODAL_CLOSE_EVENT', // done
    BRIDGE_PAY_INPUT_EVENT: 'BRIDGE_PAY_INPUT_EVENT', // done
    BRIDGE_PAY_TOKEN_REMOVE_EVENT: 'BRIDGE_PAY_TOKEN_REMOVE_EVENT', // done
  },
  RECIEVE: {
    BRIDGE_RECIEVE_MODAL_OPEN_EVENT: 'BRIDGE_RECIEVE_MODAL_OPEN_EVENT', // done
    BRIDGE_RECIEVE_MODAL_CLOSE_EVENT: 'BRIDGE_RECIEVE_MODAL_CLOSE_EVENT', // done
    BRIDGE_RECIEVE_TOKEN_REMOVE_EVENT: 'BRIDGE_RECIEVE_TOKEN_REMOVE_EVENT', // done
  },
  SLIPPAGE: {
    SLIPPAGE_MODAL_OPEN_EVENT: 'BRIDGE_SLIPPAGE_MODAL_OPEN_EVENT', // done
    SLIPPAGE_MODAL_CLOSE_EVENT: 'BRIDGE_SLIPPAGE_MODAL_CLOSE_EVENT', // done
    SLIPPAGE_TOLERANCE_CHANGE_EVENT: 'BRIDGE_SLIPPAGE_TOLERANCE_CHANGE_EVENT', // done
    SLIPPAGE_GAS_PRICE_CHANGE_EVENT: 'BRIDGE_SLIPPAGE_GAS_PRICE_CHANGE_EVENT', // done
  },
  BRIDGE_REFRESH_QOUTE_EVENT: 'BRIDGE_REFRESH_QOUTE_EVENT', // done
  BRIDGE_DETAILS_EVENT: 'BRIDGE_DETAILS_EVENT', // done
  BRIDGE_ROUTE_SELECT_EVENT: 'BRIDGE_ROUTE_SELECT_EVENT', // done
  BRIDGE_PAY_RECIEVE_TOGGLE: 'BRIDGE_PAY_RECIEVE_TOGGLE',
  BRIDGE_REVIEW_EVENT: 'BRIDGE_REVIEW_EVENT', // done
  BRIDGE_INITIATED_EVENT: 'BRIDGE_INITIATED_EVENT',
  BRIDGE_ROUTE_EVENT: 'BRIDGE_ROUTE_EVENT',
  BRIDGE_REJECT_EVENT: 'BRIDGE_REJECT_EVENT', // done
  BRIDGE_WALLET_APPROVAL_INITIATED_EVENT: 'BRIDGE_WALLET_APPROVAL_INITIATED_EVENT', // done
  BRIDGE_WALLET_APPROVAL_SUCCESS_EVENT: 'BRIDGE_WALLET_APPROVAL_SUCCESS_EVENT', // done
  BRIDGE_WALLET_APPROVAL_REJECT_EVENT: 'BRIDGE_WALLET_APPROVAL_REJECT_EVENT', // done
  BRIDGE_WALLET_APPROVAL_FAILED_EVENT: 'BRIDGE_WALLET_APPROVAL_FAILED_EVENT', // done
  BRIDGE_WALLET_TXN_REJECT_EVENT: 'BRIDGE_WALLET_TXN_REJECT_EVENT', // done
  BRIDGE_SUCCESS_EVENT: 'BRIDGE_SUCCESS_EVENT', // done
  BRIDGE_FAILED_EVENT: 'BRIDGE_FAILED_EVENT', // done
  BRIDGE_START_OVER: 'BRIDGE_START_OVER',
};

export const DCA = {
  FROM: {
    MODAL_OPEN_EVENT: 'DCA_FROM_MODAL_OPEN_EVENT',
    MODAL_CLOSE_EVENT: 'DCA_FROM_MODAL_CLOSE_EVENT',
  },
  TO: {
    MODAL_OPEN_EVENT: 'DCA_TO_MODAL_OPEN_EVENT',
    MODAL_CLOSE_EVENT: 'DCA_TO_MODAL_CLOSE_EVENT',
  },
  DCA_FROM_TO_TOGGLE_EVENT: 'DCA_FROM_TO_TOGGLE_EVENT',
  DCA_FREQUENCY_SELECT_EVENT: 'DCA_FREQUENCY_SELECT_EVENT',
  DCA_FREQUENCY_INTERVAL_INPUT_EVENT: 'DCA_FREQUENCY_INTERVAL_INPUT_EVENT',
  DCA_FREQUENCY_AMOUNT_EVENT: 'DCA_FREQUENCY_AMOUNT_EVENT',
  DCA_DEPOSIT_REVIEW_EVENT: 'DCA_DEPOSIT_REVIEW_EVENT',
  DCA_INITIATE_EVENT: 'DCA_INITIATE_EVENT',
  DCA_RETRY_EVENT: 'DCA_RETRY_EVENT',
  DCA_TOKEN_APPROVAL_INITIATED_EVENT: 'DCA_TOKEN_APPROVAL_INITIATED_EVENT',
  DCA_TOKEN_APPROVAL_SUCCESS_EVENT: 'DCA_TOKEN_APPROVAL_SUCCESS_EVENT',
  DCA_TOKEN_APPROVAL_FAILED_EVENT: 'DCA_TOKEN_APPROVAL_FAILED_EVENT',
  DCA_REJECTED_EVENT: 'DCA_REJECTED_EVENT',
  DCA_SUCCESS_EVENT: 'DCA_SUCCESS_EVENT',
  DCA_FAILED_EVENT: 'DCA_FAILED_EVENT',
};

export const POSITIONS = {
  POSITIONS_DETAILS_CLICK_EVENT: 'POSITIONS_DETAILS_CLICK_EVENT', // done
  POSITION_CLIAM: {
    POSITION_CLAIM_INITIATED_EVENT: 'POSITION_CLAIM_INITIATED_EVENT', // done
    POSITION_CLAIM_SUCCESS_EVENT: 'POSITION_CLAIM_SUCCESS_EVENT', // done
    POSITION_CLAIM_REJECTED_EVENT: 'POSITION_CLAIM_REJECTED_EVENT', // done
    POSITION_CLAIM_FAILED_EVENT: 'POSITION_CLAIM_FAILED_EVENT', // done
  },
  POSITION_EDIT_CLICK_EVENT: 'POSITION_EDIT_CLICK_EVENT', // done
  POSITION_EDIT_DCA: {
    POSITION_EDIT_DCA_FREQUENCY_INTERVAL_INPUT_EVENT: 'POSITION_EDIT_DCA_FREQUENCY_INTERVAL_INPUT_EVENT', // done
    POSITION_EDIT_DCA_FREQUENCY_AMOUNT_EVENT: 'POSITION_EDIT_DCA_FREQUENCY_AMOUNT_EVENT', // done
    POSITIONS_EDIT_DCA_TOKEN_APPROVAL: {
      POSITIONS_EDIT_DCA_TOKEN_APPROVAL_INITIATED: 'POSITIONS_EDIT_DCA_TOKEN_APPROVAL_INITIATED', // done
      POSITIONS_EDIT_DCA_TOKEN_APPROVAL_SUCCESS: 'POSITIONS_EDIT_DCA_TOKEN_APPROVAL_SUCCESS', // done
      POSITIONS_EDIT_DCA_TOKEN_APPROVAL_REJECTED: 'POSITIONS_EDIT_DCA_TOKEN_APPROVAL_REJECTED', // done
      POSITIONS_EDIT_DCA_TOKEN_APPROVAL_FAILED: 'POSITIONS_EDIT_DCA_TOKEN_APPROVAL_FAILED', // done
    },
    POSITION_EDIT_DCA_INITIATE_EVENT: 'POSITION_EDIT_DCA_INITIATE_EVENT', // done
    POSITION_EDIT_DCA_RETRY_EVENT: 'POSITION_EDIT_DCA_RETRY_EVENT', // done
    POSITION_EDIT_DCA_SUCCESS_EVENT: 'POSITION_EDIT_DCA_SUCCESS_EVENT', // done
    POSITION_EDIT_DCA_REJECTED_EVENT: 'POSITION_EDIT_DCA_REJECTED_EVENT', // done
    POSITION_EDIT_DCA_FAILED_EVENT: 'POSITION_EDIT_DCA_FAILED_EVENT', // done
  },
  POSITION_END_BTN_CLICK_EVENT: 'POSITION_END_BTN_CLICK_EVENT', // done
  POSITION_END_INITIATED_EVENT: 'POSITION_END_INITIATED_EVENT', // done
  POSITION_END_SUCCESS_EVENT: 'POSITION_END_SUCCESS_EVENT', // done
  POSITION_END_REJECTED_EVENT: 'POSITION_END_REJECTED_EVENT', // done
  POSITION_END_FAILED_EVENT: 'POSITION_END_FAILED_EVENT', // done
};

export const SOCIAL_EVENTS = 'SOCIAL_EVENTS';
