import DzapClient, { Services, OtherAbis } from '@dzapio/dzap-sdk';
import { AvailableAbis } from '../enums';

const getAbi = (availableAbis: AvailableAbis) => {
  switch (availableAbis) {
    case AvailableAbis.DzapBatchSwap:
      return DzapClient.getDZapAbi(Services.BatchSwap);
    case AvailableAbis.DzapDca:
      return DzapClient.getDZapAbi(Services.Dca);
    case AvailableAbis.DzapBridge:
      return DzapClient.getDZapAbi(Services.CrossChain);
    case AvailableAbis.Permit2:
      return DzapClient.getOtherAbi(OtherAbis.permit2);
    case AvailableAbis.Erc20:
      return DzapClient.getOtherAbi(OtherAbis.erc20);
    default:
      return [];
  }
};

export default getAbi;
