import { To, useNavigate } from 'react-router-dom';
import iconAuditActiveLight from '../../assets/Icons/Sidebar/audit-active-light.svg';
import iconAuditActiveDark from '../../assets/Icons/Sidebar/audit-active.svg';
import iconAuditLight from '../../assets/Icons/Sidebar/audit-light.svg';
import iconAuditDark from '../../assets/Icons/Sidebar/audit.svg';
import iconBSActiveLight from '../../assets/Icons/Sidebar/batch-sell-active-light.svg';
import iconBSActiveDark from '../../assets/Icons/Sidebar/batch-sell-active.svg';
import iconBSLight from '../../assets/Icons/Sidebar/batch-sell-light.svg';
import iconBSDark from '../../assets/Icons/Sidebar/batch-sell.svg';
import iconBActiveLight from '../../assets/Icons/Sidebar/bridge-active-light.svg';
import iconBActiveDark from '../../assets/Icons/Sidebar/bridge-active.svg';
import iconBALight from '../../assets/Icons/Sidebar/bridge-light.svg';
import iconBADark from '../../assets/Icons/Sidebar/bridge.svg';
import iconDCAActiveLight from '../../assets/Icons/Sidebar/dca-active-light.svg';
import iconDCAActive from '../../assets/Icons/Sidebar/dca-active.svg';
import iconDCALight from '../../assets/Icons/Sidebar/dca-light.svg';
import iconDCADark from '../../assets/Icons/Sidebar/dca.svg';
import iconDocActive from '../../assets/Icons/Sidebar/doc-active.svg';
import iconDocLight from '../../assets/Icons/Sidebar/doc-light.svg';
import iconDocDark from '../../assets/Icons/Sidebar/doc.svg';
import iconFeatureActiveLight from '../../assets/Icons/Sidebar/feature-active-light.svg';
import iconFeatureActiveDark from '../../assets/Icons/Sidebar/feature-active.svg';
import iconFeatureLight from '../../assets/Icons/Sidebar/feature-light.svg';
import iconFeatureDark from '../../assets/Icons/Sidebar/feature.svg';
import iconPositionsActiveLight from '../../assets/Icons/Sidebar/positions-active-light.svg';
import iconPositionsActiveDark from '../../assets/Icons/Sidebar/positions-active.svg';
import iconPositionsLight from '../../assets/Icons/Sidebar/positions-light.svg';
import iconPositionsDark from '../../assets/Icons/Sidebar/positions.svg';
import iconRewardsDark from '../../assets/Icons/Sidebar/rewards.svg';
import discordLight from '../../assets/Icons/discord-light.svg';
import discordDark from '../../assets/Icons/discord.svg';
import mediumLight from '../../assets/Icons/medium-light.svg';
import mediumDark from '../../assets/Icons/medium.svg';
import twitterLight from '../../assets/Icons/twitter-light.svg';
import twitterDark from '../../assets/Icons/twitter.svg';
import { isProd } from '../../config/AppConfig';
import { APP_ROUTES, SOCIAL } from '../../constants/Links';
import { SOCIAL_EVENTS } from '../../constants/analytics/events';
import { useAppDispatch } from '../../hooks/useState';
import { setIsFormReview } from '../../pages/BatchSwap/Store/Reducer';
import { trackCustomEvent } from '../../store/actions/analyticsAction';
import { SidebarMenuItem } from '../../types';
import {
  isBatchSwapPage,
  isCrossChainSwapPage,
  isDCACreatePage,
  isDCAPositionsPage,
  isRewardPage,
} from '../../utils/AppUtils';
import SidebarSubmenu from './SidebarSubmenu';

function SidebarMenu({ setSidebarOpen }: { setSidebarOpen: (value: boolean) => void }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const commonOnClick = (route: To) => {
    navigate(route);
    dispatch(setIsFormReview(false));
    setSidebarOpen(false);
  };

  const navigateToExternalLink = (link: string) => {
    window.open(link, '_blank');
  };

  const products: SidebarMenuItem[] = [
    {
      name: 'Bridge Aggregator',
      onClick: commonOnClick.bind(null, APP_ROUTES.bridge),
      iconDark: iconBADark,
      iconLight: iconBALight,
      activeIconDark: iconBActiveDark,
      activeIconLight: iconBActiveLight,
      current: isCrossChainSwapPage(),
    },
    {
      name: 'Batch Swap',
      onClick: commonOnClick.bind(null, APP_ROUTES.batchSwap),
      iconDark: iconBSDark,
      iconLight: iconBSLight,
      activeIconDark: iconBSActiveDark,
      activeIconLight: iconBSActiveLight,
      current: isBatchSwapPage(),
    },
    {
      name: 'Create DCA',
      onClick: commonOnClick.bind(null, APP_ROUTES.dca),
      iconDark: iconDCADark,
      iconLight: iconDCALight,
      activeIconLight: iconDCAActiveLight,
      activeIconDark: iconDCAActive,
      current: isDCACreatePage(),
    },
    {
      name: 'Positions',
      onClick: commonOnClick.bind(null, APP_ROUTES.dcaPositions),
      iconDark: iconPositionsDark,
      activeIconDark: iconPositionsActiveDark,
      iconLight: iconPositionsLight,
      activeIconLight: iconPositionsActiveLight,
      current: isDCAPositionsPage(),
    },
  ];

  const productsStaging: SidebarMenuItem[] = [
    {
      name: 'Bridge Aggregator',
      onClick: commonOnClick.bind(null, APP_ROUTES.bridge),
      iconDark: iconBADark,
      iconLight: iconBALight,
      activeIconLight: iconBActiveLight,
      activeIconDark: iconBActiveDark,
      current: isCrossChainSwapPage(),
    },
    {
      name: 'Batch Swap',
      onClick: commonOnClick.bind(null, APP_ROUTES.batchSwap),
      iconDark: iconBSDark,
      iconLight: iconBSLight,
      activeIconLight: iconBSActiveLight,
      activeIconDark: iconBSActiveDark,
      current: isBatchSwapPage(),
    },

    {
      name: 'Create DCA',
      onClick: commonOnClick.bind(null, APP_ROUTES.dca),
      iconDark: iconDCADark,
      iconLight: iconDCALight,
      activeIconLight: iconDCAActiveLight,
      activeIconDark: iconDCAActive,
      current: isDCACreatePage(),
    },
    {
      name: 'Positions',
      onClick: commonOnClick.bind(null, APP_ROUTES.dcaPositions),
      iconDark: iconPositionsDark,
      activeIconDark: iconPositionsActiveDark,
      iconLight: iconPositionsLight,
      activeIconLight: iconPositionsActiveLight,
      current: isDCAPositionsPage(),
    },
  ];

  const about: SidebarMenuItem[] = [
    {
      name: 'Documentation',
      onClick: () => navigateToExternalLink(APP_ROUTES.doc),
      iconDark: iconDocDark,
      iconLight: iconDocLight,
      activeIconDark: iconDocActive,
      current: false,
    },
    {
      name: 'Audit Reports',
      onClick: () => navigateToExternalLink(APP_ROUTES.auditReport),
      iconDark: iconAuditDark,
      activeIconDark: iconAuditActiveDark,
      iconLight: iconAuditLight,
      activeIconLight: iconAuditActiveLight,
      current: false,
    },
    {
      name: 'Feature Request',
      onClick: () => navigateToExternalLink(APP_ROUTES.featureReq),
      iconDark: iconFeatureDark,
      activeIconDark: iconFeatureActiveDark,
      iconLight: iconFeatureLight,
      activeIconLight: iconFeatureActiveLight,
      current: false,
    },

    ...(!isProd
      ? [
          {
            name: 'Rewards',
            onClick: commonOnClick.bind(null, APP_ROUTES.rewards),
            iconDark: iconRewardsDark,
            activeIconDark: iconRewardsDark,
            iconLight: iconRewardsDark,
            activeIconLight: iconRewardsDark,
            current: isRewardPage(),
            isCommingSoon: true,
          },
        ]
      : []),
  ];

  const community: SidebarMenuItem[] = [
    {
      iconDark: twitterDark,
      iconLight: twitterLight,
      onClick: () => {
        dispatch(
          trackCustomEvent({
            eventName: SOCIAL_EVENTS,
            eventData: {
              twitter: true,
            },
          }),
        );
        navigateToExternalLink(SOCIAL.twitter);
      },
      name: 'Twitter',
    },

    {
      iconDark: discordDark,
      iconLight: discordLight,
      name: 'Discord',
      onClick: () => {
        dispatch(
          trackCustomEvent({
            eventName: SOCIAL_EVENTS,
            eventData: {
              discord: true,
            },
          }),
        );
        navigateToExternalLink(SOCIAL.discord);
      },
    },
    {
      iconDark: mediumDark,
      iconLight: mediumLight,
      name: 'Medium',
      onClick: () => {
        dispatch(
          trackCustomEvent({
            eventName: SOCIAL_EVENTS,
            eventData: {
              medium: true,
            },
          }),
        );
        navigateToExternalLink(SOCIAL.medium);
      },
    },
  ];
  return (
    <ul className="flex flex-col flex-1 lg:overflow-y-auto md:overflow-y-auto xl:overflow-y-auto 2xl:overflow-y-auto">
      <SidebarSubmenu menu={!isProd ? productsStaging : products} isLast={false} panelName="Products" />
      <SidebarSubmenu menu={about} isLast={false} panelName="About" />
      <SidebarSubmenu menu={community} isLast panelName="Community" />
    </ul>
  );
}
export default SidebarMenu;
