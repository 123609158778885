// analyticsWorker.ts
const worker = new Worker(new URL('./sw.ts', import.meta.url));

worker.onmessage = function (event: MessageEvent) {
  console.log('Result from web worker:', event.data);
};

worker.onerror = function (event: ErrorEvent) {
  console.log('Error in web worker:', event.message);
};

export default worker;
