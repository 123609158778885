/* eslint-disable no-param-reassign */

import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { DEFAULT_SLIPPAGE, EMPTY_TOKEN } from '../../constants/AppConstants';
import { SwapTokenFormField, SwapTokenFormFieldWithTokenInfo } from '../../pages/BatchSwap/types';
import {
  CrossChainPendingTxDetailsType,
  CrossChainPendingTxState,
  CrossChainSwapFormTokenField,
} from '../../pages/Bridge/types';
import { SlippageType, TokenInfo, TokenResponse } from '../../types';

interface CacheBatchSwapTokens {
  [key: number]: {
    from: SwapTokenFormFieldWithTokenInfo[];
    to: SwapTokenFormFieldWithTokenInfo[];
  };
}

export interface CrossChainSwapFormTokenFieldWithTokenInfo extends CrossChainSwapFormTokenField {
  tokenInfo: TokenInfo;
}

interface CacheCrossChainSwapTokens {
  [key: number]: {
    from: CrossChainSwapFormTokenFieldWithTokenInfo[];
    to: CrossChainSwapFormTokenFieldWithTokenInfo[];
  };
}

interface CacheTokenList {
  [key: number]: TokenResponse;
}

export interface CacheReducer {
  batchSwapFormTokens: CacheBatchSwapTokens;
  cacheTokenList: CacheTokenList;
  cacheAdditionalTokenList: TokenResponse;
  crossChainSwapFormTokens: CacheCrossChainSwapTokens;
  userTransactionData: CrossChainPendingTxDetailsType;
  crossChainPendingTxData: CrossChainPendingTxState;
  bookmarkedRecipientAddresses: {
    [key: number]: string;
  };
  slippage: SlippageType;
}

const initialState: CacheReducer = {
  batchSwapFormTokens: {},
  cacheTokenList: {},
  cacheAdditionalTokenList: {},
  crossChainSwapFormTokens: {},
  userTransactionData: {},
  crossChainPendingTxData: {},
  bookmarkedRecipientAddresses: {},
  slippage: { swap: DEFAULT_SLIPPAGE.SWAP, bridge: DEFAULT_SLIPPAGE.BRIDGE },
};

export const cache = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    setBatchSwapFormTokens: (
      state,
      action: PayloadAction<{
        chainId: number;
        data: {
          from: SwapTokenFormField[];
          to: SwapTokenFormField[];
        };
      }>,
    ) => {
      const { data, chainId } = action.payload;
      const currentTokenList = current(state.cacheTokenList);
      const additionalTokenList = current(state.cacheAdditionalTokenList) || {};
      const currentChainTokens = currentTokenList[chainId] || {};
      const combinedTokens = { ...currentChainTokens, ...additionalTokenList };
      const mapTokenData = (tokens: SwapTokenFormField[]) =>
        tokens.map((token) => {
          const tokenContract = token.contract;
          const currentTokenItem = combinedTokens[tokenContract] || EMPTY_TOKEN;
          return {
            ...token,
            ...(currentTokenItem && { tokenInfo: currentTokenItem }),
          };
        });
      const cacheTokensWithTokenInfo = {
        from: mapTokenData(data.from),
        to: mapTokenData(data.to),
      };
      const currentState = state;
      currentState.batchSwapFormTokens = {
        ...currentState.batchSwapFormTokens,
        [`${chainId}`]: cacheTokensWithTokenInfo,
      };
    },
    setCacheTokenList: (state, action: PayloadAction<any>) => {
      const { tokenList, chainId } = action.payload;
      const currentState = state;
      currentState.cacheTokenList = {
        ...currentState.cacheTokenList,
        [`${chainId}`]: tokenList,
      };
    },
    setCacheAdditionalTokenList: (state, action: PayloadAction<TokenResponse>) => {
      const currentState = state;
      currentState.cacheAdditionalTokenList = action.payload;
    },
    setCacheCrossChainFormTokens: (
      state,
      action: PayloadAction<{
        chainId: number;
        data: {
          from: CrossChainSwapFormTokenField[];
          to: CrossChainSwapFormTokenField[];
        };
      }>,
    ) => {
      const { data } = action.payload;
      const {
        from: [{ chainId }],
      } = data;
      const currentState = state;
      if (data?.from?.every((item) => item?.chainId === chainId)) {
        currentState.crossChainSwapFormTokens = {
          ...currentState.crossChainSwapFormTokens,
          [`${chainId}`]: data,
        };
      }
    },
    setUserTransactionData: (state, action: PayloadAction<{ account: string; chainId: number; txHash: string }>) => {
      const { account, chainId, txHash } = action.payload;
      const newUserTxHash = { ...state.userTransactionData };
      const isDuplicate = newUserTxHash[account]?.some((item) => item.txHash === txHash);
      if (!isDuplicate) {
        newUserTxHash[account] = newUserTxHash[account]
          ? [...newUserTxHash[account], { chainId, txHash }]
          : [{ chainId, txHash }];
        state.userTransactionData = newUserTxHash;
      }
    },

    setCrossChainPendingTxData: (state, action: PayloadAction<CrossChainPendingTxState>) => {
      const currentState = state;
      currentState.crossChainPendingTxData = action.payload;
    },
    addBookMarkedRecipientAddresses: (state, action: PayloadAction<{ chainId: number; address: string }>) => {
      const { chainId, address } = action.payload;
      state.bookmarkedRecipientAddresses = {
        ...state.bookmarkedRecipientAddresses,
        [chainId]: address,
      };
    },
    setSlippage: (state, action: PayloadAction<SlippageType>) => {
      state.slippage = action.payload;
    },
  },
});

export const {
  setBatchSwapFormTokens,
  setCacheTokenList,
  setCacheAdditionalTokenList,
  setCacheCrossChainFormTokens,
  setUserTransactionData,
  setCrossChainPendingTxData,
  addBookMarkedRecipientAddresses,
  setSlippage,
} = cache.actions;

export default cache.reducer;
