export enum PositionStatus {
  active = 'ACTIVE',
  terminated = 'TERMINATED',
  completed = 'COMPLETED',
  swapped = 'SWAPPED',
  modifiedRate = 'MODIFIED_RATE',
  modifiedDuration = 'MODIFIED_DURATION',
  modifiedRateDuration = 'MODIFIED_RATE_AND_DURATION',
  modified = 'MODIFIED',
  withdrawn = 'WITHDRAWN',
}

export enum PositionActions {
  showInfo = 'showInfo',
  terminate = 'terminate',
  withdraw = 'withdraw',
  edit = 'edit',
  unset = '',
}

export enum PositionModals {
  unset = 0,
  showInfo = 1,
  edit = 2,
  withdraw = 3,
  terminate = 4,
}
