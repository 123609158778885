export enum Features {
  dca = 'dca',
  dcaPositions = 'dcaPositions',
  batchSwap = 'batchSwap',
  batchSell = 'batchSell',
  batchBuy = 'batchBuy',
  bridge = 'bridge',
}
export enum Networks {
  '0x1' = 1,
  '0x38' = 56,
  '0x89' = 137,
  '0xa4b1' = 42161,
}

export enum NetworkIDs {
  'zkSync' = 324,
  'polygon' = 137,
  'ethereum' = 1,
  'binance' = 56,
  'arbitrum' = 42161,
  'optimism' = 10,
  'scroll' = 534352,
  'manta' = 169,
  'blast' = 81457,
}

export enum AppEnv {
  'production' = 'production',
  'staging' = 'staging',
  'development' = 'development',
}

export enum TrxState {
  unset,
  onSubmit,
  signApproval,
  approving,
  signPermit,
  signTrx,
  mining,
  error,
}

export enum TrxSpeed {
  slow = 'slow',
  average = 'average',
  fast = 'fast',
  rapid = 'rapid',
}
export enum Versions {
  V1 = 'v1',
  V1_2 = 'v1.2',
  V1_3 = 'v1.3',
  V2 = 'v2',
  V4 = 'v4',
  V5 = 'v5',
}

export enum AvailableAbis {
  DzapBatchSwap = 'batchSwap',
  DzapDca = 'dca',
  DzapBridge = 'bridge',
  Permit2 = 'permit2',
  Erc20 = 'erc20',
}

export enum ThemeMode {
  light = 'light',
  dark = 'dark',
}

export enum PromiseStatus {
  pending = 'pending',
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

export enum Erc20Functions {
  approve = 'approve',
  allowance = 'allowance',
  balanceOf = 'balanceOf',
  symbol = 'symbol',
}

export enum DCAFunctions {
  createPosition = 'createPosition',
  terminatePosition = 'terminatePosition',
  withdrawPosition = 'withdrawPosition',
  modifyPosition = 'modifyPosition',
  batchCall = 'batchCall',
}

export enum ConnectorType {
  injected = 'injected',
  walletConnect = 'walletConnect',
  walletLink = 'walletLink',
}

export enum ENV {
  production = 'production',
  staging = 'staging',
}

export enum PostErrorAction {
  none,
  pageRefresher,
}
