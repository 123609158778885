import { createSlice } from '@reduxjs/toolkit';

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {},
  reducers: {
    trackEvent: (state, action) => {},
  },
});

export const { trackEvent } = analyticsSlice.actions;
export default analyticsSlice.reducer;
