import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import i18n from './locales/i18n';
import { store } from './store';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import DotLoader from './views/DotLoader/DotLoader';
import { cacheAppVersion, getAppVersion, getCachedAppVersion } from './utils/AppUtils';
import './polyfills';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
(function () {
  const originalJSONStringify: (
    value: any,
    replacer?: (this: any, key: string, value: any) => any,
    space?: string | number,
  ) => string = JSON.stringify;
  // eslint-disable-next-line no-extend-native, @typescript-eslint/ban-ts-comment
  // @ts-ignore
  JSON.stringify = function (
    value: any,
    replacer?: (this: any, key: string, value: any) => any,
    space?: string | number,
  ): string {
    const customReplacer: (key: string, val: any) => any = (key, val) => {
      // console.log(key, "key")
      // If the val is a BigInt, convert it to a string

      if (typeof val === 'bigint') {
        // console.trace(key, 'bigintkeys');
        return val.toString();
      }
      // If a custom replacer is provided, use it
      if (replacer) {
        return replacer(key, val);
      }
      return val;
    };
    return originalJSONStringify(value, customReplacer, space);
  };
})();

const appVersion = getAppVersion();
(function () {
  const originalJSONStringify: (
    value: any,
    replacer?: (this: any, key: string, value: any) => any,
    space?: string | number,
  ) => string = JSON.stringify;
  // eslint-disable-next-line no-extend-native, @typescript-eslint/ban-ts-comment
  // @ts-ignore
  JSON.stringify = function (
    value: any,
    replacer?: (this: any, key: string, value: any) => any,
    space?: string | number,
  ): string {
    const customReplacer: (key: string, val: any) => any = (key, val) => {
      // If the val is a BigInt, convert it to a string
      if (typeof val === 'bigint') {
        return val.toString(); // Convert BigInt to string
      }
      // If a custom replacer is provided, use it
      if (replacer) {
        return replacer(key, val);
      }
      return val;
    };
    return originalJSONStringify(value, customReplacer, space);
  };
})();
if (getCachedAppVersion() !== appVersion) {
  localStorage.clear();
  // Delete IndexedDB named 'Dzap'
  const dzapRequest = indexedDB.deleteDatabase('Dzap');
  dzapRequest.onsuccess = () => {
    console.log('IndexedDB Dzap deleted successfully');
  };
  dzapRequest.onerror = (e) => {
    console.log('Error deleting IndexedDB Dzap:', e);
  };

  // Delete IndexedDB named 'cacheStorage'
  const cacheStorageRequest = indexedDB.deleteDatabase('cacheStorage');
  cacheStorageRequest.onsuccess = () => {
    console.log('IndexedDB cacheStorage deleted successfully');
  };
  cacheStorageRequest.onerror = (e) => {
    console.log('Error deleting IndexedDB cacheStorage:', e);
  };

  cacheAppVersion(appVersion);
}

const persistor = persistStore(store);
root.render(
  <Provider store={store}>
    <PersistGate loading={<DotLoader className="ml-5 -mt-5" />} persistor={persistor}>
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </BrowserRouter>
    </PersistGate>
  </Provider>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
