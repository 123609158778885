const nonEvmChainIds = [1000, 7565164];

export const ChainIdsWithNoFeeHistory = [40];

export const nonEvmChainIdsMap = {
  bitcoin: 1000,
  solana: 7565164,
};

export const nonEvmAddMaxLenMap: {
  [key: number]: number;
} = {
  [nonEvmChainIdsMap.solana]: 44,
  [nonEvmChainIdsMap.bitcoin]: 34,
};

export const evmAddMaxLen = 42;
