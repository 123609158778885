import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dropdownDark from '../../assets/Icons/filledDownArrow.svg';
import dropdownLight from '../../assets/IconsV2/dropdown-light.svg';
import { RootState } from '../../store';
import { setIsChainSwitcher } from '../../store/reducers/AuthReducer';
import Button from '../../views/Buttons/Button';

function NetworkSwitchButton() {
  const [isImageSrcError, setIsImageSrcError] = useState(false);

  const { chainId, isSwitchNetwork } = useSelector((state: RootState) => state.auth);
  const { mode } = useSelector((state: RootState) => state.theme);
  const { supportedChainsData } = useSelector((state: RootState) => state.chains);
  const dispatch = useDispatch();
  const arrow = mode === 'light' ? dropdownLight : dropdownDark;
  const handleImageError = () => {
    setIsImageSrcError(true);
  };
  const chainInfo = supportedChainsData[chainId];

  const isSpecificKeyName = chainInfo?.name === 'Arbitrum';
  const containerClassName = ` ${
    isSpecificKeyName ? 'md:w-8 md:h-8 w-9 h-9 flex justify-center items-center bg-[#2C374B] rounded-full' : ''
  }`;

  return (
    <div>
      <Button
        onClick={() => dispatch(setIsChainSwitcher(true))}
        className={`px-4 ${isSpecificKeyName ? 'py-2' : 'py-2.5'}  h-max w-max rounded flex items-center text-blue-100 dark:text-gray-100 ${
          isSwitchNetwork ? 'bg-red-400' : 'md:bg-gray-50 md:dark:bg-blue-50'
        }`}
        key={chainId}
      >
        {isSwitchNetwork ? (
          <p className="text-sm font-bold uppercase font-inter text-blue700">Switch Network</p>
        ) : (
          <div className="relative flex items-center justify-between gap-3">
            {isImageSrcError ? null : (
              <div className={containerClassName}>
                <img
                  src={chainInfo?.logo || ''}
                  className={`rounded-full  md:static  ${
                    isSpecificKeyName ? 'md:w-5 md:h-5 w-7 h-7' : 'md:w-7 md:h-7 w-9 h-9'
                  }`}
                  alt="coin"
                  onError={handleImageError}
                />
              </div>
            )}
            <div className="flex items-center justify-between gap-x-2">
              <p className="hidden text-sm font-normal font-inter sm:block">{chainInfo?.name || 'Unkown Network'}</p>

              <img alt="arrow" className="w-3 h-3 md:w-2 md:h-2 md:static" src={arrow} />
            </div>
          </div>
        )}
      </Button>
    </div>
  );
}
export default NetworkSwitchButton;
