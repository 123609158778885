import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import arrowDark from '../../assets/Icons/arrow-side.svg';
import arrowLight from '../../assets/IconsV2/arrow-down-light.svg';
import { ThemeMode } from '../../enums';
import { RootState } from '../../store';
import { SidebarMenuItem } from '../../types';
import Button from '../Buttons/Button';

function SidebarSubmenu({ menu, panelName, isLast }: { menu: SidebarMenuItem[]; panelName: string; isLast: boolean }) {
  const [isActive, setIsActive] = useState(true);

  const handleItemClick = useCallback(
    (onClick: () => void) => () => {
      setIsActive(!isActive);
      onClick();
    },
    [isActive],
  );
  const { mode } = useSelector((state: RootState) => state.theme);
  const arrow = mode === ThemeMode.light ? arrowLight : arrowDark;
  return (
    <>
      <li key={panelName} className="mt-6">
        <Button className="flex items-center justify-between w-full px-4" onClick={handleItemClick(() => {})}>
          <p className="text-sm font-medium font-inter text-gray-150 dark:text-gray-200">{panelName}</p>
          <img src={arrow} className={`w-3 ${!isActive ? 'rotate-180' : ''}`} alt="arrow" />
        </Button>
        {isActive && menu.length > 0 && (
          <ul className="my-6 modalFadeIn">
            {menu.map(
              ({
                current,
                name,
                activeIconLight,
                activeIconDark,
                iconLight,
                iconDark,
                onClick,
                isCommingSoon = false,
              }: SidebarMenuItem) => {
                const activeIcon = mode === ThemeMode.light && activeIconLight ? activeIconLight : activeIconDark;
                const icon = mode === ThemeMode.light && iconLight ? iconLight : iconDark;
                return (
                  <li
                    className={`${current ? 'text-blue-100 dark:text-gray-300 bg-white dark:bg-white/5 font-bold' : 'text-gray-250 dark:text-gray-600'}`}
                    key={name.toString()}
                  >
                    <Button
                      className="relative flex items-center w-full gap-2 px-4 py-2 text-base font-medium transition duration-300 ease-in-out cursor-pointer font-lato hover:bg-gray-300 dark:hover:bg-blue-950"
                      onClick={onClick}
                    >
                      <div className="flex items-center justify-center">
                        <img className="h-7 w-7" src={current ? activeIcon : icon} alt="icon" />
                      </div>
                      {name}
                      {current && (
                        <div className="absolute right-0 w-1 h-full bg-green-50 rounded-bl-md rounded-tl-md" />
                      )}
                    </Button>
                  </li>
                );
              },
            )}
          </ul>
        )}
      </li>
      {isLast ? ' ' : <div className="mx-4 border-b border-gray-250 dark:border-gray-850" />}
    </>
  );
}
export default SidebarSubmenu;
