/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PostErrorAction, TrxSpeed, TrxState } from '../../enums';
import { ApprovalStatusType } from '../../pages/BatchSwap/types';
import { AllowanceType, NetworkFee, SupportedChainTokenList, TokenResponse, TokensPrice } from '../../types';
import { getCachedTrxSpeed } from '../../utils/AppUtils';

type Props = {
  tokenList: TokenResponse;
  supportedChainTokenList: SupportedChainTokenList;
  isTokenModal: boolean;
  isBridgeSourceTokenModal: boolean;
  isBridgeDestinationTokenModal: boolean;
  editTokenContract: string;
  isCombineTokenList: boolean;
  atmChainId: number;
  tokenShimmerSize: number;
  trxResponse: any;
  isRefreshData: boolean;
  isQuoteRatesLoading: boolean;
  isApproved: boolean;
  isLoading: boolean;
  isChainSwitcher: boolean;
  currentStep: number;
  trxSteps: string[];
  trxState: number;
  tokensPrice: TokensPrice;
  allowance: AllowanceType;
  isApprovalModal: boolean;
  closable: boolean;
  isSetting: boolean;
  isInvalidPage: boolean;
  requiredGasFee: number;
  customGasFee: string;
  refreshQuoteRate: number;
  trxSpeed: string;
  networkFee: NetworkFee;
  approvalStatus: ApprovalStatusType[];
  isAddRecipient: boolean;
  postErrorAction: PostErrorAction;
};

const initialState: Props = {
  tokenList: {},
  supportedChainTokenList: [],
  isTokenModal: false,
  isBridgeSourceTokenModal: false,
  isBridgeDestinationTokenModal: false,
  isInvalidPage: false,
  editTokenContract: '',
  isCombineTokenList: false,
  tokenShimmerSize: 0,
  trxResponse: undefined,
  isRefreshData: false,
  isQuoteRatesLoading: true,
  isApproved: false,
  isLoading: false,
  isChainSwitcher: false,
  currentStep: 0,
  atmChainId: 0,
  trxSteps: [],
  trxState: TrxState.unset,
  tokensPrice: {},
  allowance: {},
  isApprovalModal: false,
  closable: true,
  isSetting: false,
  refreshQuoteRate: 0,
  requiredGasFee: 0,
  trxSpeed: getCachedTrxSpeed() || TrxSpeed.average,
  customGasFee: '',
  approvalStatus: [],
  networkFee: {
    slow: 0,
    average: 0,
    fast: 0,
    rapid: 0,
  },
  isAddRecipient: false,
  postErrorAction: PostErrorAction.none,
};

export const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsTokenModal: (state, action: PayloadAction<boolean>) => {
      state.isTokenModal = action.payload;
    },
    setIsBridgeSourceTokenModal: (state, action: PayloadAction<boolean>) => {
      state.isBridgeSourceTokenModal = action.payload;
    },
    setIsBridgeDestinationTokenModal: (state, action: PayloadAction<boolean>) => {
      state.isBridgeDestinationTokenModal = action.payload;
    },
    setTokenList: (state, action: PayloadAction<TokenResponse>) => {
      state.tokenList = action.payload;
    },
    setEditTokenContract: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.editTokenContract = action.payload;
    },
    setIsCombineTokenList: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isCombineTokenList = action.payload;
    },
    setTokenShimmerSize: (state, action: PayloadAction<any>) => {
      const currentState = state;
      currentState.tokenShimmerSize = action.payload;
    },
    setTrxResponse: (state, action: PayloadAction<any>) => {
      const currentState = state;
      currentState.trxResponse = action.payload;
    },
    setIsRefreshData: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isRefreshData = action.payload;
    },
    setIsApproved: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isApproved = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isLoading = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.currentStep = action.payload;
    },
    setTrxSteps: (state, action: PayloadAction<string[]>) => {
      const currentState = state;
      currentState.trxSteps = action.payload;
    },
    setTrxState: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.trxState = action.payload;
    },
    setTokensPrice: (state, action: PayloadAction<TokensPrice>) => {
      const currentState = state;
      currentState.tokensPrice = action.payload;
    },
    setAllowance: (state, action: PayloadAction<AllowanceType>) => {
      const currentState = state;
      currentState.allowance = action.payload;
    },
    setIsApprovalModal: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isApprovalModal = action.payload;
    },
    setClosable: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.closable = action.payload;
    },
    setIsSetting: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isSetting = action.payload;
    },
    setIsInvalidPage: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isInvalidPage = action.payload;
    },
    setRequiredGasFee: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.requiredGasFee = action.payload;
    },

    setAtmChainId: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.atmChainId = action.payload;
    },
    setCustomGasFee: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.customGasFee = action.payload;
    },
    setTrxSpeed: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.trxSpeed = action.payload;
    },
    setNetworkFee: (state, action: PayloadAction<NetworkFee>) => {
      const currentState = state;
      currentState.networkFee = action.payload;
    },
    setRefreshQuoteRate: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.refreshQuoteRate = action.payload;
    },
    setIsQuoteRatesLoading: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isQuoteRatesLoading = action.payload;
    },
    setSupportedChainTokenList: (state, action: PayloadAction<SupportedChainTokenList>) => {
      const currentState = state;
      currentState.supportedChainTokenList = action.payload;
    },
    setApprovalStatus: (state, action: PayloadAction<ApprovalStatusType[]>) => {
      const currentState = state;
      currentState.approvalStatus = action.payload;
    },
    setAddRecipient: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isAddRecipient = action.payload;
    },
    setPostErrorAction: (state, action: PayloadAction<PostErrorAction>) => {
      const currentState = state;
      currentState.postErrorAction = action.payload;
    },
    resetCommonReducer: () => initialState,
  },
});

export const {
  setTokenList,
  setIsSetting,
  setIsTokenModal,
  setIsBridgeSourceTokenModal,
  setIsBridgeDestinationTokenModal,
  setEditTokenContract,
  setIsCombineTokenList,
  setTokenShimmerSize,
  setTrxResponse,
  setIsRefreshData,
  setIsApproved,
  setIsLoading,
  setCurrentStep,
  setTrxSteps,
  setTrxState,
  setTokensPrice,
  setIsApprovalModal,
  setAllowance,
  setClosable,
  setRequiredGasFee,
  setTrxSpeed,
  setCustomGasFee,
  setNetworkFee,
  setAtmChainId,
  setIsInvalidPage,
  resetCommonReducer,
  setIsQuoteRatesLoading,
  setApprovalStatus,
  setSupportedChainTokenList,
  setRefreshQuoteRate,
  setAddRecipient,
  setPostErrorAction,
} = common.actions;

export default common.reducer;
