import { PositionStatus } from '../Enums';

export const DCA_TABS = [
  {
    label: 'Active',
    key: PositionStatus.active,
  },
  {
    label: 'Completed',
    key: PositionStatus.completed,
  },
  {
    label: 'Stopped',
    key: PositionStatus.terminated,
  },
];
export const REVIEW_SCREEN_HEADER = {
  text: 'Review Order',
  navigation: 'Back',
};

export const POSITION_DETAIL_TABS = [
  { name: 'Parameters', key: 'parameters' },
  { name: 'Timeline ', key: 'timeline' },
];

export const TIMELINE_FILTER = [
  { name: 'All', key: '' },
  { name: 'Swap ', key: PositionStatus.swapped },
  { name: 'Claim ', key: PositionStatus.withdrawn },
  { name: 'End ', key: PositionStatus.terminated },
];

export const LS_KEY_DCA_POSITIONS = 'dcaPositions';

export const DCA_CONTRACT_METHODS = {
  claim: 'withdrawPosition',
  terminate: 'terminatePosition',
  edit: 'modifyPosition',
  create: 'createPosition',
};
