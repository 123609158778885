import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import storage from 'redux-persist-indexeddb-storage';
import { isProd } from '../config/AppConfig';
import multiSwapReducer from '../pages/BatchSwap/Store/Reducer';
import crossChainSwapReducer from '../pages/Bridge/Store';
import createDCAReducer from '../pages/CreateDCAPage/Store';
import dashboardDCAReducer from '../pages/Positions/Store';
import analyticsMiddleware from './middlewares/analyticMiddleware';
import analyticsReducer from './reducers/analyticsReducer';
import authReducer from './reducers/AuthReducer';
import cacheReducer from './reducers/CacheReducer';
import chainsReducer from './reducers/ChainsReducer';
import commonReducer from './reducers/CommonReducer';
import ThemeReducer from './reducers/ThemeReducer';

const persistConfig = {
  key: 'root',
  storage: storage('cacheStorage'),
  whitelist: ['cache'], // only cache will be persisted
};

const rootReducer = combineReducers({
  theme: ThemeReducer,
  common: commonReducer,
  auth: authReducer,
  multiSwap: multiSwapReducer,
  createDCA: createDCAReducer,
  dashboardDCA: dashboardDCAReducer,
  crossChain: crossChainSwapReducer,
  cache: cacheReducer,
  chains: chainsReducer,
  analytics: analyticsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(analyticsMiddleware),
  devTools: !isProd,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
