import axios from 'axios';
import urls from '../../config/urls';

export const updateGmStreak = async (account: string) => {
  try {
    const response = await axios.post(urls.gm, {
      account,
    });
    return response.data as {
      status: string;
      streak: number;
      lastCompleted: string;
      points: number;
    };
  } catch (err) {
    console.log(err);
    return null;
  }
};
