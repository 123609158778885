/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_CHAIN_ID } from '../../constants/AppConstants';
import { getCachedAccount } from '../../utils/AppUtils';
import { UserDetails } from '../../pages/Rewards/types/user';

type NativeCurrencyInfoType = {
  decimals: number;
  balance: number;
  price: number;
};

export type AuthReducer = {
  chainId: number; // take this as the default chain id across all pages
  account: string;
  isChainSwitcher: boolean;
  isSwitchNetwork: boolean;
  requestedChainId: number;
  isNativeCurrencyInfoLoading: boolean;
  nativeCurrencyInfo: NativeCurrencyInfoType;
  recipientAddress: string | null;
  userInfo: UserDetails;
  referralLink: string;
};

const initialState: AuthReducer = {
  chainId: DEFAULT_CHAIN_ID,
  account: getCachedAccount(),
  isChainSwitcher: false,
  isSwitchNetwork: false,
  requestedChainId: 0,
  isNativeCurrencyInfoLoading: false,
  nativeCurrencyInfo: {
    decimals: 18,
    balance: 0,
    price: 0,
  },
  recipientAddress: null,
  userInfo: {} as UserDetails,
  referralLink: '',
};
export const common = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setChainId: (state, action: PayloadAction<number>) => {
      state.chainId = action.payload;
    },
    setRequestedChainId: (state, action: PayloadAction<number>) => {
      state.requestedChainId = action.payload;
    },
    setIsNativeCurrencyInfoLoading: (state, action: PayloadAction<boolean>) => {
      state.isNativeCurrencyInfoLoading = action.payload;
    },
    setNativeCurrencyInfo: (state, action: PayloadAction<NativeCurrencyInfoType>) => {
      state.nativeCurrencyInfo = action.payload;
    },
    setAccount: (state, action: PayloadAction<string>) => {
      state.account = action.payload;
    },
    setIsSwitchNetwork: (state, action: PayloadAction<boolean>) => {
      state.isSwitchNetwork = action.payload;
    },
    setIsChainSwitcher: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isChainSwitcher = action.payload;
    },
    setRecipientAddress: (state, action: PayloadAction<string | null>) => {
      const currentState = state;
      currentState.recipientAddress = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<UserDetails>) => {
      const currentState = state;
      currentState.userInfo = action.payload;
    },
    setReferralLink: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.referralLink = action.payload;
    },
  },
});

export const {
  setAccount,
  setChainId,
  setIsChainSwitcher,
  setIsSwitchNetwork,
  setRequestedChainId,
  setNativeCurrencyInfo,
  setIsNativeCurrencyInfoLoading,
  setRecipientAddress,
  setUserInfo,
  setReferralLink,
} = common.actions;

export default common.reducer;
