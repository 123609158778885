import { SwapQuoteResponse } from '@dzapio/dzap-sdk';
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import omit from 'lodash.omit';
import { SwapRouteSourcesType } from '../../../types/api-response';
import { MultiSwapBtnState, MultiSwapTrxState, ShimmerState } from '../Enums';
import { BatchSwapFormData } from '../types';

type SelectedRoutes = {
  [key: string]: string;
};

export interface MultiSwapReducer {
  isFromToken: boolean;
  isEmptyList: boolean;
  noLiquidityTokens: any;
  avlBalanceForSwap: number;
  tokenPairs: string[];
  isFormReview: boolean;
  isBatchBuy: boolean;
  isSingleSwap: boolean;
  isOpenSwapRoutes: boolean;
  isOpenSwapRates: boolean;
  swapBtnState: number;
  shimmerState: number;
  trxData: any;
  trxResponse: any;
  swapSaved: number;
  toggleSendTo: boolean;
  isInsufficientGasFee: boolean;
  refreshQuoteRate: number;
  trxStatus: number;
  swapQuoteRates: SwapQuoteResponse;
  formQuotes: SwapQuoteResponse;
  isQuoteChanged: boolean;
  reviewSwapQuoteRates: SwapQuoteResponse;
  isQuoteRatesLoading: boolean;
  isSwapQuoteRateError: boolean;
  formData: BatchSwapFormData;
  isBatchSwapSourceModal: boolean;
  isBatchSwapDestinationModal: boolean;
  selectedRoutes: SelectedRoutes;
  editToken: string;
  isAnyToTokenAmountZero: boolean;
  isMaxDigitsAfterDecimalFilled: boolean;
  gasPrice: bigint;
  userRouteSelected: boolean;
  swapRouteSources: SwapRouteSourcesType[];
  isSwapSendToToggle: boolean;
}

const initialState: MultiSwapReducer = {
  isBatchBuy: true,
  isFromToken: true,
  isSingleSwap: true,
  noLiquidityTokens: [] as any[],
  avlBalanceForSwap: 0,
  isFormReview: false,
  isOpenSwapRoutes: false,
  swapBtnState: MultiSwapBtnState.unset,
  shimmerState: ShimmerState.quoteRate,
  trxData: undefined,
  trxResponse: undefined,
  swapSaved: 0,
  isInsufficientGasFee: false,
  isOpenSwapRates: false,
  toggleSendTo: false,
  isEmptyList: false,
  refreshQuoteRate: 0,
  trxStatus: MultiSwapTrxState.unset,
  swapQuoteRates: {},
  formQuotes: {},
  isQuoteChanged: false,
  reviewSwapQuoteRates: {},
  isSwapQuoteRateError: false,
  isQuoteRatesLoading: true,
  formData: {} as BatchSwapFormData,
  tokenPairs: [],
  isBatchSwapDestinationModal: false,
  isBatchSwapSourceModal: false,
  selectedRoutes: {},
  editToken: '',
  isAnyToTokenAmountZero: false,
  isMaxDigitsAfterDecimalFilled: false,
  gasPrice: BigInt(0),
  userRouteSelected: false,
  swapRouteSources: [],
  isSwapSendToToggle: false,
};

export const multiSwap = createSlice({
  name: 'multiSwap',
  initialState,
  reducers: {
    setIsFromToken: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isFromToken = action.payload;
    },
    setIsEmptyList: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isEmptyList = action.payload;
    },
    setAvlBalanceForSwap: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.avlBalanceForSwap = action.payload;
    },
    setIsFormReview: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isFormReview = action.payload;
    },
    setToggleSendTo: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.toggleSendTo = action.payload;
    },
    setIsBatchBuy: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isBatchBuy = action.payload;
    },
    setIsSingleSwap: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isSingleSwap = action.payload;
    },

    setNoLiquidityTokens: (state, action: PayloadAction<any[]>) => {
      const currentState = state;
      currentState.noLiquidityTokens = action.payload;
    },
    setSwapBtnState: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.swapBtnState = action.payload;
    },
    setShimmerState: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.shimmerState = action.payload;
    },
    setTrxData: (state, action: PayloadAction<any>) => {
      const currentState = state;
      currentState.trxData = action.payload;
    },
    setTrxResponse: (state, action: PayloadAction<any>) => {
      const currentState = state;
      currentState.trxResponse = action.payload;
    },
    setSwapSaved: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.swapSaved = action.payload;
    },
    setIsInsufficientGasFee: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isInsufficientGasFee = action.payload;
    },
    setRefreshQuoteRate: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.refreshQuoteRate = action.payload;
    },
    setTrxStatus: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.trxStatus = action.payload;
    },
    setSwapQuoteRates: (state, action: PayloadAction<SwapQuoteResponse>) => {
      const currentState = state;
      currentState.swapQuoteRates = action.payload;
    },
    setFormQuotes: (state, action: PayloadAction<SwapQuoteResponse>) => {
      const currentState = state;
      currentState.formQuotes = action.payload;
    },
    setIsQuoteChanged: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isQuoteChanged = action.payload;
    },
    setReviewSwapQuoteRates: (state, action: PayloadAction<SwapQuoteResponse>) => {
      const currentState = state;
      currentState.reviewSwapQuoteRates = action.payload;
    },
    setIsQuoteRatesLoading: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isQuoteRatesLoading = action.payload;
    },
    setIsQuoteRateError: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isSwapQuoteRateError = action.payload;
    },

    setFormData: (state, action: PayloadAction<BatchSwapFormData>) => {
      const currentState = state;
      currentState.formData = action.payload;
    },
    setIsOpenSwapRoutes: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isOpenSwapRoutes = action.payload;
    },
    setIsOpenSwapRates: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isOpenSwapRates = action.payload;
    },
    setTokenPairs: (state, action: PayloadAction<string[]>) => {
      const currentState = state;
      currentState.tokenPairs = action.payload;
    },
    setBatchSwapSourceModal: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      if (action.payload === false) {
        currentState.editToken = '';
      }
      currentState.isBatchSwapSourceModal = action.payload;
    },
    setBatchSwapDestinationModal: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      if (action.payload === false) {
        currentState.editToken = '';
      }
      currentState.isBatchSwapDestinationModal = action.payload;
    },
    setEditToken: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.editToken = action.payload;
    },
    setSelectedRoutes: (
      state,
      action: PayloadAction<
        | {
            tokenPair: string;
            selectedSource: string;
          }
        | SelectedRoutes
      >,
    ) => {
      const currentState = state;
      if (action.payload) {
        const currentStateCopy = current(state);
        const { tokenPair, selectedSource } = action.payload;

        currentState.selectedRoutes = {
          ...currentStateCopy.selectedRoutes,
          [tokenPair]: selectedSource,
        };
      } else {
        currentState.selectedRoutes = {};
      }
    },
    deleteTokenEntryFromQuoteRateAndRoutes: (
      state,
      action: PayloadAction<{
        contract: string;
      }>,
    ) => {
      const { contract } = action.payload;
      const currentState = state;
      const currentStateCopy = { ...currentState }; // Create a shallow copy of the state
      const routeTokenPairs = Object.keys(currentStateCopy.selectedRoutes);
      const tokenPairsWithContract =
        Array.isArray(routeTokenPairs) && routeTokenPairs.length > 0
          ? routeTokenPairs.filter((item) => item.includes(contract))
          : [];
      const filteredRoutes = omit(currentStateCopy.selectedRoutes, tokenPairsWithContract);
      currentState.selectedRoutes = filteredRoutes;
      const swapQuoteRatePairs = Object.keys(currentStateCopy.swapQuoteRates);
      const swapTokenPairsWithContract =
        Array.isArray(swapQuoteRatePairs) && swapQuoteRatePairs.length > 0
          ? swapQuoteRatePairs.filter((item) => item.includes(contract))
          : [];
      const filteredSwapQuoteRateTokens = omit(currentStateCopy.swapQuoteRates, swapTokenPairsWithContract);
      currentState.swapQuoteRates = filteredSwapQuoteRateTokens;
    },

    setIsAnyToTokenAmountZero: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isAnyToTokenAmountZero = action.payload;
    },
    setIsMaxDigitsAfterDecimalsFilled: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isMaxDigitsAfterDecimalFilled = action.payload;
    },
    setGasPrice: (state, action: PayloadAction<bigint>) => {
      const currentState = state;
      currentState.gasPrice = action.payload;
    },
    setUserRouteSelected: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.userRouteSelected = action.payload;
    },
    setSwapRouteSources: (state, action: PayloadAction<SwapRouteSourcesType[]>) => {
      const currentState = state;
      currentState.swapRouteSources = action.payload;
    },
    setIsSwapSendToToggle: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isSwapSendToToggle = action.payload;
    },
  },
});

export const {
  setIsFromToken,
  setAvlBalanceForSwap,
  setIsFormReview,
  setNoLiquidityTokens,
  setSwapBtnState,
  setTrxData,
  setTrxResponse,
  setShimmerState,
  setSwapSaved,
  setIsInsufficientGasFee,
  setRefreshQuoteRate,
  setTrxStatus,
  setIsBatchBuy,
  setIsEmptyList,
  setToggleSendTo,
  setSwapQuoteRates,
  setIsQuoteRatesLoading,
  setReviewSwapQuoteRates,
  setIsQuoteRateError,
  setFormData,
  setIsOpenSwapRoutes,
  setIsOpenSwapRates,
  setIsSingleSwap,
  setTokenPairs,
  setBatchSwapSourceModal,
  setBatchSwapDestinationModal,
  setSelectedRoutes,
  deleteTokenEntryFromQuoteRateAndRoutes,
  setEditToken,
  setIsAnyToTokenAmountZero,
  setIsMaxDigitsAfterDecimalsFilled,
  setGasPrice,
  setUserRouteSelected,
  setSwapRouteSources,
  setIsSwapSendToToggle,
  setFormQuotes,
  setIsQuoteChanged,
} = multiSwap.actions;

export default multiSwap.reducer;
