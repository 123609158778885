import { createSlice } from '@reduxjs/toolkit';
import { ThemeMode } from '../../enums';
import { isSystemThemeDark } from '../../utils/AppUtils';

const THEME_SLICE_NAME = 'theme';

export const themeSlice = createSlice({
  name: THEME_SLICE_NAME,
  initialState: {
    mode: ThemeMode.dark,
  },
  reducers: {
    setTheme: (state, action) => {
      localStorage.setItem(THEME_SLICE_NAME, JSON.stringify({ mode: action.payload }));
      return { ...state, mode: action.payload };
    },
    toggleTheme: (state) => {
      const newMode = state.mode === ThemeMode.light ? ThemeMode.dark : ThemeMode.light;
      localStorage.setItem(THEME_SLICE_NAME, JSON.stringify({ mode: newMode }));
      return { ...state, mode: newMode };
    },
    initializeTheme: (state) => {
      const savedTheme = localStorage.getItem(THEME_SLICE_NAME);
      const systemTheme = isSystemThemeDark() ? ThemeMode.dark : ThemeMode.light;

      let mode = systemTheme;

      if (savedTheme) {
        try {
          const parsedTheme = JSON.parse(savedTheme);
          mode = parsedTheme.mode || systemTheme;
        } catch (error) {
          console.error('Error parsing theme from local storage:', error);
        }
      }

      return { ...state, mode };
    },
  },
});

export const { setTheme, toggleTheme, initializeTheme } = themeSlice.actions;
export const selectTheme = (state: any) => state.theme?.mode;
export default themeSlice.reducer;
