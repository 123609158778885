import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import darkIcon from '../../assets/Icons/theme-icons/darkThemeIcon.svg';
import lightIcon from '../../assets/Icons/theme-icons/LightThemeIcon.svg';
import { isThemeEnabled } from '../../config/AppConfig';
import { ThemeMode } from '../../enums';
import { RootState } from '../../store';
import { initializeTheme, selectTheme, toggleTheme } from '../../store/reducers/ThemeReducer';
import ChainListModal from './ChainListModal';
import NetworkSwitchButton from './NetworkSwitchButton';

function WalletComponent() {
  const { isChainSwitcher } = useSelector((state: RootState) => state.auth);
  const { isConnected, isConnecting } = useAccount();
  const dispatch = useDispatch();
  const theme = useSelector(selectTheme);

  const setThemeAction = () => {
    dispatch(toggleTheme());
  };

  useEffect(() => {
    if (isThemeEnabled) {
      dispatch(initializeTheme());
    }
  }, []);

  useEffect(() => {
    if (theme === ThemeMode.dark) {
      document.documentElement.classList.add(ThemeMode.dark);
    } else {
      document.documentElement.classList.remove(ThemeMode.dark);
    }
  }, [theme]);

  const handleThemeSwitch = () => {
    setThemeAction();
  };
  return (
    <>
      {isChainSwitcher && <ChainListModal />}

      <div className="flex items-center ml-2 gap-2 sm:gap-4">
        {isThemeEnabled && (
          <button
            aria-label="button"
            className="flex items-center justify-center w-10 p-1 rounded h-11 bg-gray-50 dark:bg-blue-50"
            type="button"
            onClick={handleThemeSwitch}
          >
            <img alt="light" src={theme === ThemeMode.dark ? darkIcon : lightIcon} />
          </button>
        )}

        {isConnected ? null : <NetworkSwitchButton />}
        <ConnectButton label={isConnecting ? 'Connecting...' : 'Connect Wallet'} />
      </div>
    </>
  );
}
export default WalletComponent;
