import { parseUnits } from 'ethers/lib/utils';

export const fixRoundOffError = (num: number, decimals: number) => {
  const zeros = '0'.repeat(decimals);
  const upto = `1${zeros}`;
  const result = Math.round(num * +upto) / +upto;
  return result;
};

export const dissolveExponentialNotation = (x: any) => {
  let number = x;
  if (Math.abs(number) < 1.0) {
    const e = parseInt(number?.toString().split('e-')[1], 10);
    if (e) {
      number *= 10 ** e - 1;
      const numStr = `0.${new Array(e).join('0')}${number?.toString().substring(2)}`;
      number = parseFloat(numStr);
    }
  } else {
    let e = parseInt(number?.toString().split('+')[1], 10);
    if (e > 20) {
      e -= 20;
      number /= 10 ** e;
      number += parseFloat(`0.${new Array(e + 1).join('0')}`);
    }
  }
  return number;
};

export const numberToString = (x: number | string): string => {
  const number = x.toString();
  if (number.includes('e-')) {
    const mantissaExponentSplit = number.split('e-');
    const exponent = parseInt(mantissaExponentSplit[1], 10);
    const [beforeDecimal, afterDecimal] = mantissaExponentSplit[0].split('.');
    const beforeDecimalLength = beforeDecimal.length;
    const zeroes = '0'.repeat(exponent - beforeDecimalLength);
    return `0.${zeroes}${beforeDecimal}${afterDecimal && afterDecimal}`;
  }
  if (number.includes('e+')) {
    const valueExponentSplit = number.split('e+');
    const exponent = parseInt(valueExponentSplit[1], 10);
    const [beforeDecimal, afterDecimal] = valueExponentSplit[0].split('.');
    const afterDecimalLength = afterDecimal.length;
    const zeroes = '0'.repeat(exponent - afterDecimalLength);
    return `${beforeDecimal}${afterDecimal}${zeroes}`;
  }
  return number;
};

export const safeParseUnits = (balance, decimals) => {
  try {
    return Number(parseUnits(balance, decimals));
  } catch {
    return 0;
  }
};
