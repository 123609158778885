import batchSwapLight from '../assets/Icons/app-batch-swap-light.svg';
import batchSwapDark from '../assets/Icons/app-batch-swap.svg';
import bridgeLight from '../assets/Icons/app-bridge-light.svg';
import bridgeDark from '../assets/Icons/app-bridge.svg';
import dcaLight from '../assets/Icons/app-dca-light.svg';
import dcaDark from '../assets/Icons/app-dca.svg';
import { Features } from '../enums';
import { Feature } from '../types';

export const DEFAULT_CHAIN_ID = 42161;

export const FeeDescription = {
  GasFee: 'Gas Fee',
  ProtocolFee: 'Protocol Fee',
  ProviderFee: 'Provider Fee',
};

export const TWITTER_MESSAGE = 'Just swapped  tokens on DZap.io. What a DeFi experience @dzap_io';
export const TWITTER_BRIDGE_MESSAGE =
  "Successfully bridged my tokens using @DZao_io 's Meta Bridge Aggregator , Great Experience. Try it now at app.dzap.io ";

export const JOIN_DISCORD_COMMUNITY = 'Join Community';

export const FEATURES: { [key: string]: Feature } = {
  [Features.batchSwap]: {
    name: 'Batch Swap',
    key: Features.batchSwap,
    iconDark: batchSwapDark,
    iconLight: batchSwapLight,
  },
  [Features.dca]: {
    name: 'DCA',
    key: Features.dca,
    iconDark: dcaDark,
    iconLight: dcaLight,
  },
  [Features.bridge]: {
    name: 'Bridge',
    key: Features.bridge,
    iconDark: bridgeDark,
    iconLight: bridgeLight,
  },
};

export const TXN_STATUS = {
  pending: 'pending',
  inProgress: 'in-progress',
  success: 'success',
  rejected: 'rejected',
  error: 'error',
  reverted: 'reverted',
};

export const TOKEN_APPROVAL_API_ERROR_CODES = {
  rejected: 'ACTION_REJECTED',
};

export const API_STATUS = {
  pending: 'pending',
  inProgress: 'in-progress',
  success: 'success',
  rejected: 'rejected',
  error: 'error',
};

export const ERC20_METHODS = {
  allowance: 'allowance',
  balanceOf: 'balanceOf',
};
export const CONTRACT_METHOD = {
  batchSwap: 'swapTokensToTokens',
};
export const BATCH_SWAP_BUFFER = 9;
export const BUFFER_DIVISOR = 10;
export const LOCAL_STORAGE_TOKEN_LIST_KEY = 'tokenList';
export const LS_FAV_TOKEN_LIST_KEY = 'favTokenList';
export const METAMASK = 'MetaMask';
export const ERC_20_ABI_PATH = 'erc20-abi.json';
export const DEFAULT_DECIMAL_PLACES = 4;

export const DEFAULT_PERMIT =
  '0x000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000400000000000000000000000000000000000000000000000000000000000000000';

export const DEFAULT_TOKEN = {
  symbol: 'USDC',
  contract: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  name: 'USD Coin (PoS)',
  decimals: 6,
  balance: '0',
  price: 1,
  logo: 'https://assets.unmarshal.io/tokens/matic_0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174.png',
  verified: false,
  swapRate: 1,
  allowance: '0',
};

export const EMPTY_TOKEN = {
  symbol: 'EMPTY1',
  contract: 'EMPTY',
  name: 'EMPTY',
  decimals: 0,
  balance: '0',
  price: 0,
  logo: '',
  verified: false,
  swapRate: 0,
  allowance: '0',
};

export const LOWEST_SLIPPAGE = 0.01;

export const HIGHEST_SLIPPAGE = 49.99;

export const SLIPPAGE = {
  low: 0.1,
  standard: 0.5,
  high: 1,
};

export const DEFAULT_SLIPPAGE = {
  SWAP: SLIPPAGE.standard,
  BRIDGE: SLIPPAGE.high,
};

export const LS_APP_KEY = 'app';

export const APP_TABS = [
  {
    label: 'DCA',
    key: Features.dca,
  },
  {
    label: 'Batch Sell',
    key: Features.batchSell,
  },
  {
    label: 'Batch Buy',
    key: Features.batchBuy,
  },
];

export const SIGN_EXPIRATION_BUFFER = 60 * 60 * 30 * 1000;

export const PERMIT2_EXPIRATION_BUFFER = 60 * 60 * 24 * 30 * 1000;
export const EXPIRATION_BUFFER = 60 * 2 * 1000;
export const GAS_FEE_BUFFER = 0.05;

export const REFERRAL = 'referral';

export const getAccountAddress = () => localStorage.getItem('account') || '';

export const DECIMAL_TRUNCATION_LIMIT = 8;
