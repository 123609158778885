export enum MultiSwapBtnState {
  unset = 0,
  onSubmit = 1,
  onBatchSwap = 2,
}

export enum ShimmerState {
  unset = 0,
  page = 1,
  primary = 2,
  list = 3,
  quoteRate = 4,
}

export enum MultiSwapTrxState {
  unset = 0,
  wait = 1,
  processing = 2,
  success = 3,
  error = 4,
  rejected = 5,
}

export enum DzapActions {
  batchSell = 'batchSell',
  batchBuy = 'batchBuy',
}
