export const SOCIAL = {
  twitter: 'https://twitter.com/dzap_io',
  telegram: 'https://t.me/dzap_io',
  medium: 'https://medium.com/@dzap_io',
  discord: 'https://discord.gg/aRHESJB9md',
  youtube: 'https://www.youtube.com/channel/UChwCZ6hcampuJwY-DltN99A',
  instagram: 'https://www.instagram.com/DZap_io/',
  linkedin: 'https://www.linkedin.com/in/DZapIO',
  reddit: 'https://www.reddit.com/user/DZap_io/',
};

export const APP_ROUTES = {
  batchSwap: '/swap',
  bridge: '/bridge',
  memeCoin: '/meme-coin',
  dca: '/dca',
  dcaPositions: '/positions',
  rewards: '/rewards',
  doc: 'https://docs.dzap.io',
  featureReq: 'https://features.dzap.io/',
  auditReport: 'https://docs.dzap.io/protocol/audit-reports',
};
