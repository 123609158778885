import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { POSITION_DETAIL_TABS } from '../Constants';
import { PositionModals, PositionStatus } from '../Enums';

type Props = {
  activePositionDetailTab: string;
  positionDetails: any;
  positionModal: number;
  isPositionDetailModal: boolean;
  positions: any;
  positionAction: string;
  activePositionTab: string;
  modifiedAmt: number;
  modifiedFrequency: number;
  modifiedPeriod: number;
  timelineFilter: string;
  isApprovalRequired: boolean | undefined;
  positionsUpdated: boolean;
};

const initialState: Props = {
  activePositionDetailTab: POSITION_DETAIL_TABS[0].key,
  positionDetails: undefined,
  positions: [],
  positionModal: PositionModals.unset,
  isPositionDetailModal: false,
  positionAction: '',
  activePositionTab: PositionStatus.active,
  modifiedAmt: 0,
  modifiedPeriod: 0,
  modifiedFrequency: 0,
  timelineFilter: '',
  isApprovalRequired: undefined,
  positionsUpdated: false,
};

export const dashboardDCA = createSlice({
  name: 'dashboardDCA',
  initialState,
  reducers: {
    setActivePositionDetailTab: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.activePositionDetailTab = action.payload;
    },
    setPositionDetails: (state, action: PayloadAction<any>) => {
      const currentState = state;
      currentState.positionDetails = action.payload;
    },
    setPositionModal: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.positionModal = action.payload;
    },
    setIsPositionDetailModal: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isPositionDetailModal = action.payload;
    },
    setPositions: (state, action: PayloadAction<any>) => {
      const currentState = state;
      currentState.positions = action.payload;
    },
    setPositionAction: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.positionAction = action.payload;
    },
    setActivePositionTab: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.activePositionTab = action.payload;
    },
    setModifiedAmt: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.modifiedAmt = action.payload;
    },
    setModifiedPeriod: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.modifiedPeriod = action.payload;
    },
    setModifiedFrequency: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.modifiedFrequency = action.payload;
    },
    setIsApprovalRequired: (state, action: PayloadAction<boolean | undefined>) => {
      const currentState = state;
      currentState.isApprovalRequired = action.payload;
    },
    setTimelineFilter: (state, action: PayloadAction<string>) => {
      const currentState = state;
      currentState.timelineFilter = action.payload;
    },
    setPositionsUpdated: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.positionsUpdated = action.payload;
    },
  },
});

export const {
  setActivePositionDetailTab,
  setPositionDetails,
  setPositionModal,
  setPositions,
  setPositionAction,
  setActivePositionTab,
  setModifiedFrequency,
  setModifiedPeriod,
  setModifiedAmt,
  setTimelineFilter,
  setIsPositionDetailModal,
  setIsApprovalRequired,
  setPositionsUpdated,
} = dashboardDCA.actions;

export default dashboardDCA.reducer;
