/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import hamburgerDark from '../../assets/Icons/hamburger-menu.svg';
import hamburgerLight from '../../assets/IconsV2/hamburger-menu-light.svg';
import transaction from '../../assets/IconsV2/transaction.svg';
import logo from '../../assets/Logo/dzap.svg';
import WalletComponent from '../../components/Wallet/WalletComponent';
import { isProd } from '../../config/AppConfig';
import { isEmpty } from '../../helper/utilityFunctions';
import { setShowPendingTxModal } from '../../pages/Bridge/Store';
import { RootState } from '../../store';
import { isCrossChainSwapPage } from '../../utils/AppUtils';
import { updateGmStreak } from '../../services/user/gmStreak';
import { setUserInfo } from '../../store/reducers/AuthReducer';
import HoverInfoTip from '../Misc/HoverInfoTip';

interface IconButtonProps {
  onClick: () => void;
  icon: string;
  tooltip: string;
  text: string;
}

function IconButton({ onClick, icon, tooltip, text }: IconButtonProps) {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <button
      type="button"
      onClick={handleClick}
      className=" cursor-pointer  rounded-xl bg-bgwalletIcon"
      aria-label={tooltip}
    >
      <div className="flex gap-1">
        <img className="w-5 h-5 " src={icon} alt={tooltip} />
        <p className="text-xs hidden sm:block md:text-sm">{text}</p>
      </div>
    </button>
  );
}

const pluralize = (count: number, val: string) => (count > 1 ? `${val}s` : val);

function Header({ setSidebarOpen }: { setSidebarOpen: (open: boolean) => void }) {
  const SideBarOpen = () => {
    setSidebarOpen(true);
  };
  const crossChainPage = isCrossChainSwapPage();

  const { mode } = useSelector((state: RootState) => state.theme);
  const { account, userInfo } = useSelector((state: RootState) => state.auth);
  const hamburger = mode === 'light' ? hamburgerLight : hamburgerDark;
  const { showPendingTxModal } = useSelector((state: RootState) => state.crossChain);
  const dispatch = useDispatch();
  const handleShowPendingTxModal = () => {
    dispatch(setShowPendingTxModal(!showPendingTxModal));
  };

  const isMobile = window.innerWidth < 769;

  const isGmStreakAvailable = !isEmpty(userInfo)
    ? new Date(userInfo.streaks.gm.lastCompleted).toDateString() !== new Date().toDateString()
    : false;

  const handleGmStreak = async () => {
    if (isGmStreakAvailable) {
      const res = await updateGmStreak(account);
      if (res && res.status === 'success') {
        dispatch(
          setUserInfo({
            ...userInfo,
            points: res.points,
            streaks: {
              ...userInfo.streaks,
              gm: {
                lastCompleted: res.lastCompleted,
                consecutiveDays: res.streak,
              },
            },
          }),
        );
      }
    }
  };

  return (
    <header className="flex items-center justify-between w-full px-4 py-3 bg-transparent md:bg-white shrink-0 lg:pl-16 lg:pr-4 md:dark:bg-backgroundPrimary lg:h-max">
      <div className="hidden h-max lg:block">
        <a className="items-center" href="https://app.dzap.io/">
          <img src={logo} alt="logo" />
        </a>
      </div>

      <div className="flex items-center justify-between w-full lg:justify-end">
        <div className="flex items-center shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-400 lg:hidden" onClick={SideBarOpen}>
            <span className="sr-only">Open sidebar</span>
            <img className="w-8" src={hamburger} alt="hamburger" />
          </button>
        </div>
        {!isEmpty(userInfo) && !isProd && (
          <div className="flex items-center justify-center ml-auto">
            <HoverInfoTip
              desc={isGmStreakAvailable ? 'Say Good Morning' : 'You are done for the day'}
              underline={false}
            >
              <button
                onClick={handleGmStreak}
                type="button"
                style={{
                  background: '#1A1B1F',
                }}
                className=" header-streak text-nowrap rounded-lg text-white font-bold mr-2"
              >
                <span className="text-xs">{`${userInfo.streaks.gm.consecutiveDays} 🔥`}</span>
              </button>
            </HoverInfoTip>
            {!isMobile && (
              <div
                style={{
                  background: '#1A1B1F',
                }}
                className=" p-3 rounded-lg text-white font-bold"
              >
                {`${userInfo.points} ${pluralize(userInfo.points, 'Point')}`}
              </div>
            )}
          </div>
        )}
        {crossChainPage && account && (
          <div className="flex  items-center  p-2 bg-blue-300 rounded-md ml-2">
            <IconButton
              onClick={() => handleShowPendingTxModal()}
              icon={transaction}
              tooltip="Pending Transaction"
              text="Transaction"
            />
            {/* <p className="text-xs font-medium font-inter text-walletInfoIcon"> Transaction</p> */}
          </div>
        )}

        <WalletComponent />
      </div>
    </header>
  );
}
export default React.memo(Header);
