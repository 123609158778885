import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_CHAIN_ID } from '../../../constants/AppConstants';
import { RecipientAddresses } from '../../../types';
import { SwapRouteSourcesType } from '../../../types/api-response';
import { CrossChainTokens, TransformedCrossChainQuoteRateResponse } from '../types';

export interface CrossChainSwapReducer {
  isFromToken: boolean;
  srcChainId: number;
  tokenPairs: string[];
  tokens: CrossChainTokens;
  isFormReview: boolean;
  isRouteInfo: boolean;
  shouldShowSwapRates: boolean;
  crossChainQuoteRates: TransformedCrossChainQuoteRateResponse;
  formQuote: TransformedCrossChainQuoteRateResponse;
  isOneToManyTrx: boolean;
  isReceiverAddress: boolean;
  swapRouteSources: SwapRouteSourcesType[];
  showPendingTxModal: boolean;
  isCrossChainApiResponseError: boolean;
  isBridgeTokenListLoading: boolean;
  recipientAddresses: RecipientAddresses | null;
  sendToAnotherAddressOnEvmChains: boolean;
  isQuotesChanged: boolean;
  isBridgeSendToToggle: boolean;
}

const initialState: CrossChainSwapReducer = {
  isFormReview: false,
  srcChainId: DEFAULT_CHAIN_ID,
  tokenPairs: [],
  tokens: {},
  isFromToken: true,
  isRouteInfo: !true,
  crossChainQuoteRates: {},
  formQuote: {},
  isOneToManyTrx: false,
  shouldShowSwapRates: false,
  isReceiverAddress: false,
  swapRouteSources: [],
  showPendingTxModal: false,
  isCrossChainApiResponseError: false,
  isBridgeTokenListLoading: true,
  recipientAddresses: null,
  sendToAnotherAddressOnEvmChains: false,
  isQuotesChanged: false,
  isBridgeSendToToggle: false,
};

export const crossChainSwap = createSlice({
  name: 'crossChainSwap',
  initialState,
  reducers: {
    setIsFromToken: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isFromToken = action.payload;
    },
    setIsFormReview: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isFormReview = action.payload;
    },
    setIsRouteInfo: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isRouteInfo = action.payload;
    },
    setTokenPairs: (state, action: PayloadAction<string[]>) => {
      const currentState = state;
      currentState.tokenPairs = action.payload;
    },
    setTokens: (state, action: PayloadAction<CrossChainTokens>) => {
      const currentState = state;
      currentState.tokens = {
        ...currentState.tokens,
        ...action.payload,
      };
    },
    setSrcChainId: (state, action: PayloadAction<number>) => {
      const currentState = state;
      currentState.srcChainId = action.payload;
    },
    setIsOneToManyTrx: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isOneToManyTrx = action.payload;
    },
    setShouldShowSwapRates: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.shouldShowSwapRates = action.payload;
    },
    setIsReceiverAddress: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isReceiverAddress = action.payload;
    },
    setCrossChainQuoteRates: (state, action: PayloadAction<TransformedCrossChainQuoteRateResponse>) => {
      const currentState = state;
      currentState.crossChainQuoteRates = action.payload;
    },
    setFormQuote: (state, action: PayloadAction<TransformedCrossChainQuoteRateResponse>) => {
      const currentState = state;
      currentState.formQuote = action.payload;
    },
    setSwapRouteSources: (state, action: PayloadAction<SwapRouteSourcesType[]>) => {
      const currentState = state;
      currentState.swapRouteSources = action.payload;
    },
    setShowPendingTxModal: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.showPendingTxModal = action.payload;
    },
    setIsCrossChainApiResponseError: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isCrossChainApiResponseError = action.payload;
    },
    setIsBridgeTokenListLoading: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isBridgeTokenListLoading = action.payload;
    },
    setRecipientAddresses: (state, action: PayloadAction<RecipientAddresses | null>) => {
      const currentState = state;
      currentState.recipientAddresses = action.payload;
    },
    setSendToAnotherAddressOnEvmChains: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.sendToAnotherAddressOnEvmChains = action.payload;
    },
    setIsQuotesChanged: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isQuotesChanged = action.payload;
    },
    setIsBridgeSendToToggle: (state, action: PayloadAction<boolean>) => {
      const currentState = state;
      currentState.isBridgeSendToToggle = action.payload;
    },
  },
});

export const {
  setIsFromToken,
  setIsFormReview,
  setTokenPairs,
  setSrcChainId,
  setIsRouteInfo,
  setCrossChainQuoteRates,
  setIsOneToManyTrx,
  setShouldShowSwapRates,
  setIsReceiverAddress,
  setTokens,
  setSwapRouteSources,
  setShowPendingTxModal,
  setIsCrossChainApiResponseError,
  setIsBridgeTokenListLoading,
  setRecipientAddresses,
  setFormQuote,
  setSendToAnotherAddressOnEvmChains,
  setIsQuotesChanged,
  setIsBridgeSendToToggle,
} = crossChainSwap.actions;

export default crossChainSwap.reducer;
