import { useDispatch, useSelector } from 'react-redux';
import { ThemeMode } from '../../enums';
import { setSrcChainId } from '../../pages/Bridge/Store';
import { RootState } from '../../store';
import { setChainId } from '../../store/reducers/AuthReducer';
import { setCurrentChainInfo } from '../../store/reducers/ChainsReducer';
import { Feature } from '../../types';
import Button from '../../views/Buttons/Button';
import Spinner from '../../views/Misc/CircularSpinner';
import HoverInfoTip from '../../views/Misc/HoverInfoTip';

interface ChainListItemProps {
  chainId: number;
  features: Feature[];
  connectedChainId: number;
  isActive: boolean;
  requestedChainId: number;
}

enum FeaturesToDisplay {
  BatchSwap = 'batchSwap',
  DCA = 'dca',
}

function ChainListItem({ chainId, features, connectedChainId, isActive, requestedChainId }: ChainListItemProps) {
  const dispatch = useDispatch();
  const { mode } = useSelector((state: RootState) => state.theme);
  const { supportedChainsData } = useSelector((state: RootState) => state.chains);

  const handleButtonClick = () => {
    localStorage.setItem('chainId', chainId.toString());
    dispatch(setCurrentChainInfo(supportedChainsData[chainId]));
    dispatch(setChainId(chainId));
    dispatch(setSrcChainId(chainId));
  };
  const chainInfo = supportedChainsData[chainId];
  const isSpecificKeyName = chainInfo?.name === 'Arbitrum';
  const containerClassName = ` ${isSpecificKeyName ? 'md:w-10 md:h-10 w-8 h-8 flex justify-center items-center bg-[#2C374B] rounded-full' : ''}`;

  return (
    <Button
      onClick={handleButtonClick}
      className={`hover:bg-gray-2100 dark:hover:bg-blue-1200 flex items-center justify-between w-full p-3 rounded-lg h-max bg-gray-450 dark:bg-blue-1350 ${
        isActive ? 'border-green-450 border' : ''
      }`}
      key={chainId}
    >
      <div className="flex items-center gap-4">
        <div className="relative">
          <div className={containerClassName}>
            <img
              src={chainInfo?.logo || ''}
              className={` rounded-full ${isSpecificKeyName ? 'md:w-8 md:h-8 w-6 h-6' : 'md:w-10 md:h-10 w-8 h-8'} `}
              alt="coin"
              loading="lazy"
            />
          </div>

          {connectedChainId === chainId && (
            <span className="absolute top-0 w-2 h-2 rounded-full bg-green-450 md:left-7 left-5" />
          )}
        </div>

        <p className="text-xs text-gray-1250 dark:text-gray-500 font-medium md:text-base">{chainInfo?.name || ''}</p>
      </div>
      <p className="flex items-center gap-2 text-xs font-bold">
        {features
          .filter(({ key }) => Object.values(FeaturesToDisplay).includes(key as FeaturesToDisplay))
          .map(({ iconDark, iconLight, name }) => {
            const icon = mode === ThemeMode.light ? iconLight : iconDark;
            return (
              <HoverInfoTip underline={false} key={name} desc={name}>
                <img src={icon} className="flex items-center w-8 h-8" alt={name} />
              </HoverInfoTip>
            );
          })}
      </p>
      {requestedChainId === chainId && <Spinner />}
    </Button>
  );
}

export default ChainListItem;
